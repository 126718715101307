import React, { useEffect, useRef, useState } from "react";
import Style from "./Header.module.scss";
import Chinesh from "assets/SVG/Horizental.svg";
import Monogram from "assets/SVG/Monogram.svg";
// import MenuHamburger from "../../../public/assets/ICON/Menu.png";
import { useDispatch, useSelector } from "react-redux";
// import { move } from "../../../redux/Features/animation/animationSlice";
// import { lodingPage } from "../../../redux/Features/loding/lodingSlice";
// import { headerPage } from "../../../redux/Features/Header/headerSlice";
import ChinshWhite from "assets/SVG/Chinesh - Horizental (White).svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "hook/useMediaQuery";
import { useCountStore } from "zstand/animatin";
import { useShowMenuMobile } from "zstand/ShowMenuMobile";
// import HeaderLogoMobile from "Logos/HeaderLogoMobile"
// import Draver from "./Draver/Draver"
const Header = ({showHeader}) => {
  const count = useCountStore((state) => state.count);
  const { goAnime, backAnime } = useCountStore((state) => state.actions);
  const { show ,dontShow} = useShowMenuMobile((state) => state.actions);
  const showInputSearch = useSelector((state) => state.search.value);
  const [active, setActive] = useState(count);
  const [hoverLogo, setHoverLogo] = useState(false);
  const [showLogoDiyaris, setShowLogoDiyaris] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState("بینش");
  const pathnameNormal = location.pathname.replace("/", "");
  const Customer = location.pathname.startsWith("/Customer");
  const matches = useMediaQuery("(min-width: 400px)");
  const hederMobile = useRef(null);
  let Header;
  let LogoDiyaris;
  let MenuOne;
  let MenuTwo;
  let MenuThree;
  let MenuFour;
  let LogoChinesh;
  switch (count) {
    case "noAnime":
      Header = null;
      LogoDiyaris = null;
      break;
    case "startAnime":
      Header = Style.ShowHeader;
      LogoDiyaris = Style.AnimeLogo;
      MenuOne = Style.AnimeMenuOne;
      MenuTwo = Style.AnimeMenuTwo;
      MenuThree = Style.AnimeMenuThree;
      MenuFour = Style.AnimeMenuFour;
      LogoChinesh = Style.Logo;
      break;
    case "startBackAnime":
      Header = Style.NotShowHeader;
      LogoDiyaris = Style.AnimeLogo;
      MenuOne  =  Style.AnimeMenuOne;
      MenuTwo = Style.AnimeMenuTwo;
      MenuThree = Style.AnimeMenuThree;
      MenuFour = Style.AnimeMenuFour;
      LogoChinesh = Style.Logo
      break;
    case "stopAnime":
      Header = Style.ShowHeaderWithoutAnime;
      LogoDiyaris = Style.AnimeLogoWithOutAnime;
      MenuOne = Style.AnimeMenuOneWithOutAnime;
      MenuTwo = Style.AnimeMenuTwoWithOutAnime;
      MenuThree = Style.AnimeMenuThreeWithOutAnime;
      MenuFour = Style.AnimeMenuFourWithOutAnime;
      LogoChinesh = Style.LogoWithOutAnime;
      break;
  }

  const handleShowMenu = () => {
    switch (pathnameNormal) {
      case "diyaris": {
        setShowMenu("دیاریس");
        break;
      }
      case "services": {
        setShowMenu("خدمات");
        break;
      }
      case "customer": {
        setShowMenu("مشتریان");
        break;
      }
      case "AboutUs": {
        setShowMenu("درباره ما");
        break;
      }
      case "OrderRequest": {
        setShowMenu("سفارش");
        break;
      }
      case "CareerOpportunities": {
        setShowMenu("فرصت شغلی");
        break;
      }
    }
  };
  useEffect(() => {
    if (count === null) {
      setActive(location.pathname);
    }
    handleShowMenu(location.pathname);
  }, [location.pathname]);
  const handleHoverLogo = () => {
    setHoverLogo(true);
  };
  const notHaverLogo = () => {
    setHoverLogo(false);
  };
  const handleLogoHeader = () => {
    setShowLogoDiyaris(true);
  };
  const handleNotShowLogoHeader = () => {
    setShowLogoDiyaris(false);
  };

  return (
    <div dir="">
      {matches ? (
        <>
          <div className={Header}>
            <div
              className={`${
                count === "startBackAnime" ? Style.FaidOut : ""
              } flex flex-col items-center justify-between h-full `}
            >
              <div className={LogoDiyaris}>
                <img
                  src={Monogram}
                  alt="Logo Monogram for Header"
                  width={80}
                  // className={Style.Monogram}
                  className="mr-auto ml-auto mt-8 cursor-pointer"
                  onClick={() => {
                    // dispatch(move(0));
                    navigate("/");
                    backAnime();
                    dontShow();
                  }}
                />
              </div>

              <ul
                // className={Style.VerticalList}
                className="flex flex-col items-center justify-between h-[45%]"
              >
                <li
                  className={`${Style.verticalListLi} ${Style.ActiveMneu} ${MenuOne}`}
                  onClick={() => {
                    setActive(0);
                    // dispatch(lodingPage(false));
                    // dispatch(move(2));
                    // dispatch(headerPage(""));
                    navigate("/");
                    goAnime();
                  }}
                >
                  بینـــــــش
                </li>

                <li
                  className={`${Style.verticalListLi}  ${
                    active === "/diyaris" ? Style.ActiveMneu : null
                  } ${MenuTwo}`}
                  onClick={() => {
                    setActive("/diyaris");
                    navigate("/diyaris");
                    showHeader();
                  }}
                >
                  <Link href="/diyaris"> دیاریــــس</Link>
                </li>
                <li
                  onClick={() => {
                    setActive("/services");
                    navigate("/services");
                    showHeader();
                  }}
                  className={`${Style.verticalListLi}  ${
                    active === "/services" ? Style.ActiveMneu : null
                  } ${MenuThree}`}
                >
                  <Link href="/services"> خدمـــــات</Link>
                </li>
                <li
                  className={`${Style.verticalListLi}  ${
                    Customer ? Style.ActiveMneu : null
                  } ${MenuFour}`}
                  onClick={() => {
                    setActive("/customer");
                    navigate("/Customer");
                    showHeader();
                  }}
                >
                  <Link href="/Customer">مشـــتریان </Link>
                </li>
              </ul>

              <div
                className={LogoChinesh}
                onMouseEnter={handleHoverLogo}
                onMouseLeave={notHaverLogo}
              >
                <a href="https://chinesh.com/" target="_blank">
                  {" "}
                  <img
                    src={Chinesh}
                    alt="Chinesh"
                    width={150}
                    className={hoverLogo === true ? Style.NotShowLogo : null}
                  />
                  <img
                    src={ChinshWhite}
                    alt="ChinshWhite"
                    width={150}
                    className={hoverLogo === true ? null : Style.NotShowLogo}
                    color="black"
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`z-30 relative flex flex-row  items-center justify-between`}
            ref={hederMobile}
          >
            <img
              src={Monogram}
              alt="Logo Monogram for Header "
              width={70}
              className=" mr-4 mt-3 "
              onClick={() => {
                // dispatch(move(0));
                navigate("/");
                backAnime();
                dontShow();
              }}
            />
            <div>
              {showLogoDiyaris ? (
                <div className=" relative  ">
                  {/* {showInputSearch ? null : <HeaderLogoMobile />} */}
                </div>
              ) : (
                <div>
                  {/* {showInputSearch ? null : (
                    // <p className="text-4xl text-white border-t-4 border-b-4 left-36 top-6 fixed">
                    //   {" "}
                    //   {showMenu}{" "}
                    // </p>
                  )} */}
                </div>
              )}
            </div>
            <div>
              {/* <Draver
                handleLogoHeader={handleLogoHeader}
                handleNotShowLogoHeader={handleNotShowLogoHeader}
              /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
