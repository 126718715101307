import React from "react";
import Styles from "./Binesh.module.scss";
import { useSelector } from "react-redux";
import useMediaQuery from "hook/useMediaQuery";
import { useCountStore } from "zstand/animatin";
import useWindowSize from "hook/useWindowSize";
import Footer from "Layout/Footer/Footer";
import Slogan from "../Slogan";

const ContainerBlack = ({ showHeader, activeProp }) => {
  const matches = useMediaQuery("(min-width: 832px)");
  // let count = 0; // Use let instead of const for reassignment
  const count = useCountStore((state) => state.count);
  const { width, height } = useWindowSize();
  const NewHeight = height - 300;
  let blackContainer;
  let Paragraph;
  let ShowOneText;
  let ShowTwoText;
  let ShowThreeText;
  let showPragrph;
  switch (count) {
    case "noAnime":
      blackContainer = null;
      Paragraph = Styles.Hiden;
      ShowOneText = null;
      ShowTwoText = null;
      ShowThreeText = null;
      break;
    case "startAnime":
      if (matches === true) {
        blackContainer = Styles.BlackContainer;
        Paragraph = Styles.animate;
        break;
      } else {
        blackContainer = Styles.BlackContainerMobile;
        Paragraph = Styles.ParagraphMobile;
        break;
      }
    case "stopAnime":
      if (matches === true) {
      Paragraph = Styles.animateWitoutAnime;
      blackContainer = Styles.NotAnime;
      ShowTwoText = Styles.NotAnimeShowTwo;
      ShowThreeText = Styles.NotAnimeShowThree;
      ShowOneText = Styles.NotAnimeShowOne;
      break;
    } else {
      blackContainer = Styles.BlackContainerMobile;
      Paragraph = Styles.ParagraphMobile;
      break;
    }
    case "startBackAnime":
      blackContainer = Styles.BackBlackContainer;
      Paragraph = Styles.BackParagraph;
      break;
    // case  "stopAnime":
    //   blackContainer = Styles.BlackContainerWitoutAnime;
    //   Paragraph = Styles.animateWitoutAnime;
    //   break;

  }
  return (
    <div className="" dir="rtl">
      {matches && <Slogan/>}
      <div className={blackContainer} dir="rtl">
        <div className={matches ? "" : `${Styles.DetailsCustomer} mx-auto rounded-2xl pb-6`}  style={{ height: matches ? "auto" : `${height - 300}px`  }}>
          <p className={`${Paragraph}`}>
            {/* <span className={ShowOneText}> */}
              شرکت دیاریس با برنامه‌ریزی و طراحی استراتژی، عناصر با اهمیت شناسه
              و{" "}
            {/* </span> */}
            {/* <span className={ShowTwoText}> */}
              {" "}
              معماری یک سازمان را به گونه‌ای طراحی می‌کند که عوامل برون و درون{" "}
            {/* </span> */}
            {/* <span className={ShowThreeText}> */}
              {" "}
              سازمانی از یکپارچگی جمعی و هدفی واقع گرایانه برخوردار شوند.
            {/* </span> */}
          </p>
          
        </div>
        {matches === false ? <div><br /><br /><br /><br /> <Footer/> </div> : null}
      </div>
    </div>
  );
};

export default ContainerBlack;
