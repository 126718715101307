import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import Layout from "Layout/Layout";
import Diyaris from "pages/Diyaris/Diyaris";
import Service from "pages/Service/Service";
import Customer from "pages/customer/Coustomers";
import AboutUs from "pages/AboutUs/AboutUs";
import OrderRequest from "pages/OrderRequest/OrderRequest";
import CareerOpportunities from "pages/CareerOpportunities/CareerOpportunities";
import NoMatch from "pages/NoMatch/NoMatch";
import CustomerDetials from "pages/CustomerDetials/CustomerDetials";
import MyComponent from "MyComponent";

// import Service from "../pages/services/Services";
// import Customer from "../pages/customer/Coustomers";
// import MyComponent from "../MyComponents";

const AppRoute = () => {
  return (
    <Router>
      <MyComponent>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="Diyaris" element={<Diyaris />} />
          <Route path="Services" element={<Service />} />
          {/* <Route path="Customer" element={<Customer />} /> */}
          {/* <Route path="/Customer" element={<Customer />}> */}
          <Route path="Customer" element={<Customer />} />
          <Route path="Customer/:id" element={<CustomerDetials />} />
          {/* </Route> */}
          <Route path="About Us" element={<AboutUs />} />
          <Route path="OrderRequest" element={<OrderRequest />} />
          <Route path="CareerOpportunities" element={<CareerOpportunities />} />
          <Route path="*" element={<NoMatch />} />
          {/* <Route path="dashboard" element={<Dashboard />} /> */}

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          {/* <Route path="*" element={<NoMatch />} /> */}
        </Route>
     
      </Routes>
      
      </MyComponent>
    </Router>
  );
};

export default AppRoute;
