import React from "react";
import Style from "./Style.module.css"
const PageFive = () => {
  return (
    <div className={Style.PageFive}>
      <div className={Style.Cloum} >
        <p className={Style.Pragragh}>
          محصول از نگاه دیاریس در این بخش به دو گونه ارائه شونده از یک سازمان و
          قابل تحویل از سوی مشتریان هدف همان سازمان دسته‌بندی می‌شود.
        </p >
        <h2>۰۱ - محصول از نوع کالا</h2>
        <p className={Style.Pragragh}>
          کالا به اقلام قابل لمسی گفته می‌شود که نیاز انسان با آن برآورده شود.
          در این بین روش استفاده، مقدار و موارد دیگری که تغییر در معیار انتخاب
          ایجاد نماید، تمایز در کالا بوجود می‌آورد.
        </p>
        <h2>۰۲ - محصول از نوع خدمت</h2>
        <p className={Style.Pragragh}>
          خدمت به عنوان وظیفه‌ای تعین شده از سوی یک یا چند فرد که به شکل غیر
          ملموس به مشتری ارائه می‌شود. در اینگونه محصول، شرط
        </p>
      </div>
      <div className={Style.Cloum}>
        <p className={Style.Pragragh}>احساس یکی از ارکان اصلی در ارائه‌ی خدمت می‌باشد.</p>
        <p className={Style.Pragragh}>
          <p className={Style.PragraghSpecial}>
            برنامه‌ریزی و طراحی محصول به عنوان یک اثر در روند خلق یک محصول جدید،
            برای خریده شدن از سوی مشتریان هدف یک کسب و کار، بشمار می‌آید. حال در
            این بین، خلق مفهوم برای طراحی محصول بسیار ضروری است. چرا که تولید و
            توسعه ایده‌ در اثر بخشی برای رسیدن به یک محصول جدید نیازمند مفهومی
            عمیق در استفاده از سوی مشتریان است.
          </p>
          <p>
            در طراحی محصول فرآیند‌هایی از جمله نیاز، تحلیل پیرامون، تصوّر،
            آفرینش راهکار جدید، آزمون اثر اولیه و تصحیح محصول به عنوان روند
            پیشروی برای خلق اثر تا پایان نتیجه گیری آن نیاز است.
          </p>
        </p>
   
      </div>
      <div className={Style.Cloum}>
        <p className={Style.Pragragh}>
        یک رویه جامع از برنامه‌ریزی و طراحی محصول“برنامه‌ریزی و طراحی محصول،
          فرآیند شناخت یک فرصت در بازار، تعریف مشخصی از طرح مسئله، طرح راه حل مناسب
          برای آن مسئله، ارزیابی و نتیجه‌یابی برای راه حل برای رفع نیاز مخاطبان
          واقعی”{" "}
        </p>
        <p className={Style.Pragragh}>
        عوامل اثر بخش در اقبال یک محصول از سوی یک مخاطب
        </p>
        <div>
            <p className={Style.PragraghSpecial}>• چگونگی کار کرد محصول</p>
            <p className={Style.PragraghSpecial}>• چگونه کنترل می‌شوند </p>
            <p className={Style.PragraghSpecial}>• تعامل میان مصرف کننده و تکنولوژی</p>
            <p className={Style.PragraghSpecial}>• درک صحیح روایت مفهومی یک محصول توسط کاربران</p>
            <p className={Style.PragraghSpecial}>• نوآوری در طراحی محصول بعنوان هدایت کننده‌ی نیاز مصرف کننده باشد</p>
            <p className={Style.PragraghSpecial}>• بهبود در رقابت پذیری</p>
        </div>
      </div>
    </div>
  );
};

export default PageFive;
