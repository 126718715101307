import React from "react";
import Style from "./Style.module.css";
const PageThree = () => {
  return (
    <div className={Style.PageThree}>
      <div className={Style.Cloum}>
        <p className={Style.Pragragh}>
          شناسه سازمانی، به عنوان یک ابزار در برنامه راهبردی جهت رسیدن به هدف‌ها
          و چشم اندازها شناخته می‌شود. شناسه سازمان‌ها در یک نگاه می‌بایست برای
          منابع انسانی در هر سازمان احساس، دریافت، حس و فکر مشترک از ارزش ها و
          ویژگی های آن سازمان، ارائه دهد.
        </p>
        <p className={Style.Pragragh}>
          شناسه یا هویت برای سازمان‌ها، تصویری از درون سازمان به بیان مفهوم غنی
          از ارزش‌های آن مجموعه بر پیکره‌ی هستی و وجود منابع انسانی و تمامی
          ذینفعان آن سازمان بیان می‌کند.
        </p>
        <p className={Style.Pragragh}>
          این بیان در تمام دوره‌های زندگی و تجلی یک شرکت به عنوان یک موجود زنده
          و پویا فعالیت می‌کند. از اینرو این بیان به عنوان یک عنصر در تشکیل
          سازمان و غنای آن اهمیت دارد.
        </p>
        <p className={Style.Pragragh}>
          این شناسه در ارائه‌ی درونیات اعضای اصلی یک شرکت که بنیان‌های رابطه‌مند
          آن سازمان را شکل می‌دهند، نقش بسزایی دارد. به بیان
        </p>
      </div>
      <div className={Style.Cloum}>
        <p className={Style.Pragragh}>
          ساده‌تر از شناسه سازمان تمامی رفتارها، فرم‌ها، شکل‌ها و احساسات روایت
          می‌شود.
        </p>
        <p className={Style.Pragragh}>
          شناسه نام تجاری محصول یا سازمان به عنوان اولین منبعی است که قدرت خلق
          تصویر را در ذهن مخاطبان آن سازمان ترسیم می‌کند.
        </p>
        <p className={Style.Pragragh}>
          دیاریس در این محصول به کشف تمامی زیر ساخت‌های موجود و نهفته یک محصول
          یا سازمان می‌پردازد. بر اساس طرح و برنامه های موجود در کسب و کار آن
          سازمان، روایتی از آن محصول یا سازمان ترسیم می‌کند که تمامی حالت‌ها و
          رفتارهای پایدار سازمان در این مرحله ترسیم می‌شود.
        </p>
        <p className={Style.Pragragh}>در محصول تدوین شناسه نام تجاری، کلید واژه‌</p>
      </div>
      <div className={Style.Cloum}>
        <p className={Style.Pragragh}>
          های تصویر جهت اثر بخشی حالاتی که یک محصول یا سازمان، در ارائه از خود
          در کنار مخاطبان خود نیاز دارد تولید می‌شود.
        </p>
        <h2 className={Style.H2}>الزامات پایه‌ای در تدوین و طراحی شناسه سازمان</h2>
        <div>
            <p className={Style.PragraghSpecial}>۰۱ - طراحی سازمان </p>
            <p className={Style.PragraghSpecial}>۰۲ - طراحی رفتار سازمان  </p>
            <p className={Style.PragraghSpecial}>۰۳ - طراحی فرهنگ سازمان</p>
            <p className={Style.PragraghSpecial}>۰۴ - طراحی روابط و زبان سازمان </p>
            <p className={Style.PragraghSpecial}>۰۵ - طراحی فلسفه و روح سازمان</p>
        </div>
      </div>
    </div>
  );
};

export default PageThree;
