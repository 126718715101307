import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  value: true ,
}

export const lodingSlice = createSlice({
  name: 'lodingPlay',
  initialState,
  reducers: {
    lodingPage:(state,action)=>{
      state.value = action.payload
    },
   
  },
})

export const {lodingPage} = lodingSlice.actions
export default lodingSlice.reducer