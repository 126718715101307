import React from "react";
import LogoChinesh from "assets/SVG/Chinesh - Horizental (White).svg";
import { useNavigate } from "react-router-dom";
const MobileFooter = () => {
  const navigate = useNavigate();
  return (
    <div
      className="bg-black  w-[100vw] h-24 mx-auto bottom-0 text-white "
      dir="rtl"
    >
      <div className="flex flex-col ">
        <ul className="flex flex-col justify-start mx-[16px] flex-wrap items-start text-[18px]  ">
          <li
            className="text-right border-t-2 w-full py-4"
            onClick={() => {
              navigate("/About Us");
            }}
          >
            ارتباط با ما
          </li>
          <li
            className="text-right border-t-2 w-full py-4 "
            onClick={() => {
              // dispatch(headerPage(null));
              navigate("/OrderRequest");
            }}
          >
            درخواست سفارش
          </li>
          <li
            className="text-right border-t-2 w-full py-4"
            onClick={() => {
              navigate("/CareerOpportunities");
            }}
          >
            فرصت های شغلی
          </li>
          <li className="text-right border-t-2 w-full py-4">
            <div className="flex  flex-col items-start justify-between  ">
              <img
                src={LogoChinesh}
                alt="LogoChinesh"
                width={100}
                className=""
              />
            </div>
          </li>
          {/* <li className="text-right  mt-2">
            <span>HOLDING CHINES</span>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default MobileFooter;
