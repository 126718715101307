import React from "react";
import Style from "./Style.module.css";
import CircleInner from "assets/SVG/CircleInner.svg";
import CircleOuter from "assets/SVG/CircleOuter.svg";
import DSign from "assets/SVG/DSign.svg";


import { useSelector } from "react-redux";
const Loding = () => {
    const lodingOk = useSelector((state) => state.loding.value)
  return (
    <div className={ Style.Loding}>
      <div className={Style.Logo}>
        <img src={CircleOuter} className={`${Style.CircleOuter}` } alt="CircleOuter Diyaris"  />
        <img src={CircleInner} className={Style.CircleInner}  alt="CircleInner Diyaris"  />
        <img src={DSign} className={Style.DSign}  alt="دیاریس"  />
      </div>
    </div>
  );
};

export default Loding;
