import React from 'react'
import LogoDiyatis from "assets/SVG/Typography.svg"
const LogoMobileDyiaris = () => {
  return (
    <div>
      <img src={LogoDiyatis} alt="LogoDiyatis" width={150} />
    </div>
  )
}

export default LogoMobileDyiaris