import Footer from 'Layout/Footer/Footer'
import React from 'react'

const MyComponent = ({children}) => {
  return (
    <div>
      <div>{children}</div>
      {/* <Footer/> */}
    </div>
  )
}

export default MyComponent