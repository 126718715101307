

import { createSlice } from '@reduxjs/toolkit';

// const interface = {
//     value: number
// }

const initialState = {
    value: 0
}

export const newAnimeSlice = createSlice({
    name: 'newAnime',
    initialState,
    reducers: {

        moveByCounter: (state) => { state.value += 1 }
    }
})

export const { moveByCounter } = newAnimeSlice.actions;

export default newAnimeSlice.reducer;