import React, { useEffect, useRef, useState } from "react";
import Style from "./Style.module.scss";
import Chinesh from "assets/SVG/Horizental.svg";
import Monogram from "assets/SVG/Monogram.svg";
import {useSelector} from "react-redux";
import ChinshWhite from "assets/SVG/Chinesh - Horizental (White).svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "hook/useMediaQuery";
import { useCountStore } from "zstand/animatin";
import MobileMenu from "./Mobile/MobileMenu";
import HeaderLogoMobile from "components/LogoMobileDyiaris/LogoMobileDyiaris";

const Header = () => {
  const count = useSelector((state) => state.header.value);
  const { goAnime, backAnime, stopAnime , noAnime} = useCountStore(
    (state) => state.actions
  );
  const showInputSearch = useSelector((state) => state.search.value);
  const [active, setActive] = useState(count);
  const [hoverLogo, setHoverLogo] = useState(false);
  const [showLogoDiyaris, setShowLogoDiyaris] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState("بینــــش");
  const pathnameNormal = location.pathname.replace("/", "");
  const Customer = location.pathname.startsWith("/Customer");
  const [showLine,setShoeLine] = useState(true);
  const matches = useMediaQuery("(min-width: 832px)");
  const hederMobile = useRef(null);

  const handleShowMenu = () => {
    switch (pathnameNormal) {
      case "diyaris": {
        setShowMenu("دیاریـس");
        setShoeLine(true);
        break;
      }
      case "services": {
        setShowMenu("خدمــات");
        setShoeLine(true);
        break;
      }
      case "About%20Us": {
        setShowMenu(null);
        setShoeLine(false);
        break;
      }
      case "OrderRequest": {
        setShowMenu(null);
        setShoeLine(false);
        break;
      }
      case "CareerOpportunities": {
        setShowMenu(null);
        setShoeLine(false);
        break;
      }
      case "Customer": {
        setShowMenu("مشتریان");
        setShoeLine(true);
        break;
      }
    }
  };
  useEffect(() => {
    if (count === null) {
      setActive(location.pathname);
    }
    handleShowMenu(location.pathname);
  }, [location.pathname]);
  const handleHoverLogo = () => {
    setHoverLogo(true);
  };
  const notHaverLogo = () => {
    setHoverLogo(false);
  };
  const handleLogoHeader = () => {
    setShowLogoDiyaris(true);
    setShoeLine(true);
  };
  const handleNotShowLogoHeader = () => {
    setShowLogoDiyaris(false);
    if(location.pathname === "/About%20Us" || location.pathname === "/OrderRequest" || location.pathname === "/CareerOpportunities"){
      setShoeLine(false);
    }else{
      setShoeLine(true);
    }
    
  };

  return (
    <div dir="">
      {matches ? (
        <>
          <div className={Style.ShowHeader}>
            <div className="flex flex-col items-center justify-between h-full">
              <div>
                <img
                  src={Monogram}
                  alt="Logo Monogram for Header"
                  width={80}
                  // className={Style.Monogram}
                  className="mr-auto ml-auto mt-8"
                  onClick={() => {
                    // dispatch(move(0));
                    navigate("/");
                    backAnime();
                  }}
                />
              </div>
              <ul
                // className={Style.VerticalList}
                className="flex flex-col items-center justify-between h-[45%]"
              >
                <li
                  className={`${Style.verticalListLi}`}
                  onClick={() => {
                    setActive(0);
                    // dispatch(lodingPage(false));
                    // dispatch(move(2));
                    // dispatch(headerPage(""));
                    navigate("/");
                    stopAnime();
                  }}
                >
                  بینـــــــش
                </li>

                <li
                  className={`${Style.verticalListLi}  ${
                    active === "/diyaris" ? Style.ActiveMneu : null
                  }`}
                  onClick={() => {
                    setActive("/diyaris");
                    navigate("/diyaris");
                  }}
                >
                  <Link href="/diyaris"> دیاریــــس</Link>
                </li>
                <li
                  onClick={() => {
                    setActive("/services");
                    navigate("/services");
                  }}
                  className={`${Style.verticalListLi}  ${
                    active === "/services" ? Style.ActiveMneu : null
                  }`}
                >
                  <Link href="/services"> خدمـــــات</Link>
                </li>
                <li
                  className={`${Style.verticalListLi}  ${
                    Customer ? Style.ActiveMneu : null
                  }`}
                  onClick={() => {
                    setActive("/customer");
                    navigate("/Customer");
                  }}
                >
                  <Link href="/Customer">مشـــتریان </Link>
                </li>
              </ul>

              <div
                className={Style.Logo}
                onMouseEnter={handleHoverLogo}
                onMouseLeave={notHaverLogo}
              >
                <a href="https://chinesh.com/" target="_blank">
                  {" "}
                  <img
                    src={Chinesh}
                    alt="Chinesh"
                    width={150}
                    className={hoverLogo === true ? Style.NotShowLogo : null}
                  />
                  <img
                    src={ChinshWhite}
                    alt="ChinshWhite"
                    width={150}
                    className={hoverLogo === true ? null : Style.NotShowLogo}
                    color="black"
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`z-30  flex flex-row  items-center justify-between w-[100vw] h-20 fixed top-0 py-2 ${Style.ImgMobile} px-8 right-0 left-0`}
            ref={hederMobile}
            dir="rtl"
          >
            <img
              src={Monogram}
              alt="Logo Monogram for Header "
              width={49}
              onClick={() => {
                navigate("/");
                noAnime();
              }}
            />
            {/* } */}
            <div className="flex flex-col justify-around items-center h-10 relative -top-2">
            {showLine === true && <div className="w-[8rem] h-[2px] bg-white relative top-0"></div>}   
              <div>
                {showLogoDiyaris ? (
                  <div className=" h-8 pt-[2px] ">
                    {/* {showInputSearch ? null : <HeaderLogoMobile />} */}
                    <HeaderLogoMobile />
                  </div>
                ) : (
                  <div>
                    {showInputSearch ? null : (
                      <p className="text-[36px] font-bold text-white  h-8 ">
                        {" "}
                        {showMenu}{" "}
                      </p>
                    )}
                  </div>
                )}
              </div>
              {showLine === true && <div className="w-[8rem] h-[2px] bg-white relative top-4"></div> }
            </div>

            <div>
              <MobileMenu
                handleLogoHeader={handleLogoHeader}
                handleNotShowLogoHeader={handleNotShowLogoHeader}
              />
              {/* <MenuComponent/> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Header;