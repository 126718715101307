import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  value: null ,
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    headerPage:(state,action)=>{
      state.value = action.payload
    },
   
  },
})

export const {headerPage} = headerSlice.actions
export default headerSlice.reducer