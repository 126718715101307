import React, { useEffect, useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import { useLocation, useNavigate } from "react-router-dom";
import { useChangeColor } from "zstand/BlackOrGreen";
const MenuFooterPage = () => {
  const location = useLocation();
  const [state, setState] = useState(null);
  const splideRef = useRef(null);
  const navigate = useNavigate();
  const color = useChangeColor((state) => state.color);
  useEffect(() => {
    if (location.pathname === "/About%20Us") {
      setState(0);
    } else if (location.pathname === "/OrderRequest") {
      setState(1);
    } else if (location.pathname === "/CareerOpportunities") {
      setState(2);
    }

    if (splideRef.current) {
      splideRef.current.splide.go(state);
    }
  }, [location.pathname, state]);
  const handleSlideChange = (splide) => {
    setState(splide.index);

    // Assuming you have routes like "/About%20Us", "/OrderRequest", "/CareerOpportunities"
    const pages = ["/About%20Us", "/OrderRequest", "/CareerOpportunities"];
    navigate(pages[splide.index]);
  };

  return (
    <div
      className={`w-[100vw]  ${
        color === "Black" ? "bg-black" : "bg-[#00c846]"
      } fixed z-20 top-20 h-16`}
    >
      <div
        className={` ${color === "Black" ? "opacity-0" : "text-opacity-100"}`}
      >
        <Splide
          className="text-black relative -top-[2.5rem]"
          aria-label="Menu"
          ref={splideRef}
          options={{
            rewind: true,
            arrows: false,
            gap: "2px",
            type: "loop",
            direction: "rtl",
            perPage: 1,
            perMove: 1,
            focus: "center",
            pagination: false,
            wheel: true,
            omitEnd: true,
            speed: 1000,
            isNavigation: true,
            pauseOnFocus: true,
            start: state,
            breakpoints: {
              1213: {
                perPage: 1,
              },
            },
          }}
          onMoved={handleSlideChange}
        >
          <SplideSlide
            className={`${
              state === 0
              ? "border-[3px]   mx-2 h-12  border-[#00c846] font-bold text-[24px] px-2 py-1 "
              : "text-[18px] px-4 py-2   hover:text-black text-black rounded-full"
            } text-center`}
          >
            <span>ارتباط با ما</span>
          </SplideSlide>

          <SplideSlide
            className={`${
              state === 1
              ? "border-[3px]   mx-2 h-12  border-[#00c846] font-bold text-[24px] px-2 py-1 "
              : "text-[18px] px-4 py-2   hover:text-black text-black rounded-full"
            } text-center`}
          >
            <span>درخواست سفارش</span>
          </SplideSlide>
          <SplideSlide
            className={`${
              state === 2
              ? "border-[3px]   mx-2 h-12  border-[#00c846] font-bold text-[24px] px-2 py-1 "
              : "text-[18px] px-4 py-2   hover:text-black text-black rounded-full"
            } text-center`}
          >
            <span>فرصت های شغلی</span>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
};

export default MenuFooterPage;
