import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  value: false ,
}

export const scrollSlice = createSlice({
  name: 'scrollPlay',
  initialState,
  reducers: {
    scrollPage:(state,action)=>{
      state.value = action.payload
    },
   
  },
})

export const {scrollPage} = scrollSlice.actions
export default scrollSlice.reducer