import useWindowSize from "hook/useWindowSize";
import React, { useState } from "react";
import InputSms from "./Forms/InputSms";
import InputCode from "./Forms/InputCode";
import FormAboutUs from "./Forms/FormAboutUs";
import Style from "./Style.module.scss"
import Footer from "Layout/Footer/Footer";
const MobileAboutUs = () => {
  const [state, setState] = useState(0);
  const { width, height } = useWindowSize();

  //این هم برای تنظیم اندازه ای که کانیتر اطلاعات ما می گیرد تنظیم می شود
  const padingHeightBottom = height / 10;
  const handleSms = () => {
    setState(1);
  };
  const handleCode = () => {
    setState(2);
  };
  return (
    <div>
      {/* <div className="w-[100vw] bg-[#00c846] fixed top-20 h-16"></div> */}
      <div>
        <div
           className={`${Style.Body}  mx-auto relative top-[10rem] -z-20 text-[21px] text-white`}
           id="myContainer"
           style={{ height: `${height - 370}px` }}
        >
          <div className={`px-4`}>
            <p>
              دانستن نقطه نظرها، دیدگاه‌ها، پیشنهادها، و انتقادات شما برای گروه
              چینش حیاتی است. همچنین برای شروع کامل یک همکاری دقیق، لازم است شما
              تصویری از سازمان خود در ذهن ما ترسیم کنید. پس ما را در هر آنچه که
              باید بدانیم شریک بدانید و از هر آنچه که باید برای شما انجام دهیم
              مطلع سازید. پر کردن فرم‌های این صفحه بهترین روش مناسب است.. همراهی
              در تصویرها و تصورهای شما آرزوی چینش است.
            </p>
            <div className={Style.Form}>
              <p>
                در حال حاضر اگر مایل به ارائه اطلاعات کامل از سازمان خود نیستید
                و دوست دارید گروه چینش هر چه زودتر با شما ارتباط برقرار کند،
                لطفا مینی فرم زیر را پر کنید.
              </p>

              {state === 0 ? (
                <InputSms handleSms={handleSms} />
              ) : state === 1 ? (
                <InputCode handleCode={handleCode} />
              ) : (
                <FormAboutUs />
              )}
            </div>
          </div>
          <div className="mt-4">
                  <Footer />
                </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAboutUs;
