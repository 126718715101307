import { useEffect, useState } from 'react';

function useScreenHeight() {
  const [adjustedHeight, setAdjustedHeight] = useState(window.innerHeight - 215);

  useEffect(() => {
    // Function to update the screen height when the window is resized
    function handleResize() {
      setAdjustedHeight(window.innerHeight - 215);
    }

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return adjustedHeight;
}

export default useScreenHeight;
