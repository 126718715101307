import React from "react";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
const schema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required().email(),
    password: Yup.string().required().min(7),
  });
const PartOne = ({nextPage}) => {
    const formik = useFormik({
        initialValues: {
          name: "",
          email: "",
          password: "",
        },
    
        // Pass the Yup schema to validate the form
        validationSchema: schema,
    
        // Handle form submission
        onSubmit: async ({ name, email, password }) => {
          // Make a request to your backend to store the data
        },
      });
      const { errors, touched, values, handleChange, handleSubmit } = formik;
      const handlePage = ()=>{
        nextPage();
      }
    
  return (
    <div>
      {" "}
      <form onSubmit={handleSubmit} method="POST">
        <div className="flex flex-col justify-between mt-4 items-center ">
        <div className="flex  flex-col justify-around p-1 w-full">
           
           <input
             type="text"
             name="Name"
             placeholder="نام"
             // value={values.name}
             // onChange={handleChange}
             id="Name"
             className="appearance-none block w-full border border-black text-gray-700   rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
           />
       

         {errors.name && touched.name && <span>{errors.name}</span>}

           <input
             type="text"
             name="Family"
             placeholder="نام‌خانوادگی"
             // value={values.name}
             // onChange={handleChange}
             id="Family"
             className="appearance-none block w-full border border-black text-gray-700   rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
           />
       
 

           <input
             type="text"
             name="Address"
             placeholder="منطقه محل سکونت"
             // value={values.name}
             // onChange={handleChange}
             id="Address"
             className="appearance-none block w-full border border-black text-gray-700   rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
           />

       </div>
       <div className="flex  flex-col justify-around p-1 w-full">

        
           <input
             type="text"
             name="Phone"
             placeholder="تلفن"
             className="appearance-none block w-full border border-black text-gray-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
             // value={values.email}
             // onChange={handleChange}
             id="Phone"
           />
       
           <input
             type="email"
             name="email"
             placeholder="ایمیل"
             className="appearance-none block w-full border border-black text-gray-700   rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
             // value={values.email}
             // onChange={handleChange}
             id="email"
           />
         

         {errors.email && touched.email && <span>{errors.email}</span>}

           <select className="appearance-none block w-full h-12 border border-black text-gray-700  rounded  px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
             <option value="پایان خدمت">وظعیت پایان خدمت </option>
             <option value="مئاف دائم">معاف دائم</option>
             <option value="معاف موقت">معاف موقت</option>
             <option value="منتظر خدمت">منتظر خدمت</option>
           </select>

       </div>
       <div className="flex  flex-col justify-around p-1  w-full">
    
           <input
             type="date"
             name="berthday"
             placeholder="تاریخ تولد"
             className="appearance-none block w-full border border-black text-gray-700   rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
             // value={values.password}
             // onChange={handleChange}
             id="berthday"
           />
        

         {errors.password && touched.password && (
           <span>{errors.password}</span>
         )}

           <input
             type="text"
             name="place-of-birth"
             placeholder="محل تولد"
             className="appearance-none block w-full border border-black  text-gray-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
             // value={values.password}
             // onChange={handleChange}
             id="place-of-birth"
           />
       

         {errors.password && touched.password && (
           <span>{errors.password}</span>
         )}
       
           <select className="appearance-none block  h-12 w-full border border-black  text-gray-700   rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
           <option value="0">وضعیت تاهل</option>
             <option value="1">مجرد</option>
             <option value="2">متاهل</option>
             <option value="3">جدا شده</option>
           </select>
         
       </div>
       <div className="flex  flex-col justify-around p-1 w-full">

         
           <input
             type="text"
             name="Name"
             placeholder="رشته تحصیلی"
             // value={values.name}
             // onChange={handleChange}
             id="Name"
             className="border border-black appearance-none block w-full  text-gray-700   rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
           />
       

         {errors.name && touched.name && <span>{errors.name}</span>}
        
           <input
             type="text"
             name="Family"
             placeholder="مدت زمان سابقه کار"
             // value={values.name}
             // onChange={handleChange}
             id="Family"
             className="appearance-none block w-full border border-black text-gray-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
           />
        

        
           <select className="appearance-none block w-full border border-black text-gray-700 h-12  rounded  px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
             <option value="0">میزان تحصیلات (انتخاب کنید)</option>
             <option value="دیپلم">دیپلم</option>
             <option value="فوق دیپلم">فوق دیپلم</option>
             <option value="کارشناسی">کارشناسی</option>
             <option value="کارشناسی ارشد"> کارشناسی ارشد</option>
             <option value="دکترا"> دکترا</option>
           </select>
        
       </div>
     </div>
        <div className="flex  flex-col justify-around p-1 mt-2 items-end" >
          <button className="text-[#00c846]  focus:shadow-outline w-[10rem] focus:outline-none  border border-black-2 border border-black-[#00c846]  font-bold py-2 px-4 rounded-full" onClick={handlePage}>
            صفحه بعد
          </button>
        </div>
      </form>
    </div>
  );
};

export default PartOne;
