import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  value: false ,
}

export const searchSlice = createSlice({
  name: 'searchEnable',
  initialState,
  reducers: {
    searchInput:(state,action)=>{
      state.value = action.payload
    },
   
  },
})

export const {searchInput} = searchSlice.actions
export default searchSlice.reducer