"use client";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./Slogen.css";
import useMediaQuery from "hook/useMediaQuery";
import { useCountStore } from "zstand/animatin";
import { useChangeColor } from "zstand/BlackOrGreen";

const Slogan = ({ showHeader, activeProp }) => {
  const matches = useMediaQuery("(min-width: 832px)");
  const count = useCountStore((state) => state.count);
  const color = useChangeColor((state)=>state.color);
  let Container;
  let Text;

  if (count === "startAnime") {
    Container = "Slogan";
    Text = "TextShow";
  } else if (count === "startBackAnime") {
    Container = "BackSlogan";
    Text = "BackText";
  }else if (count === "stopAnime"){
    Container = "SloganWitoutAnime";
    Text = "TextShowWitoutAnime";
  }

  let ContainerMobile;

  if (count === 1 || count === 2) {
    ContainerMobile = "notAnime";
  } else if (count === 0 || count === 3) {
    ContainerMobile = "Hiden";
  }

  return (
    <div>
      {matches ? (
        <div className={`${Container} `}>
          <p className={Text}>توسعه و خلق شناسه صنعت</p>
        </div>
      ) : (
        <div
        className={`w-[100vw]  ${color === "Black" ? "bg-black" : "bg-[#00c846]"} fixed top-20 h-16 text-center py-3 text-[23px]`} 
        // className={`w-full bg-[#00c846] ${ContainerMobile} fixed top-28 flex  justify-center text-white`}
        >
          <p>توسعه و خلق شناسه صنعت</p>
        </div>
      )}
    </div>
  );
};

export default Slogan;
