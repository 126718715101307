import Footer from "Layout/Footer/Footer";
import "./App.css";

import AppRoute from "./Router/router";

function App() {
  return (
    <div>
        <AppRoute />
    </div>
  );
}

export default App;