import React, { useEffect, useState } from "react";
// import Image from "next/image";
import Typography from "assets/SVG/Typography.svg";

import Styles from "./Style.module.scss";
// import Link from "next/link";
// import { useDispatch } from "react-redux";
// import { useRouter } from "next/navigation";
// import { headerPage } from "../../../redux/Features/Header/headerSlice";
// import { move } from "../../../redux/Features/animation/animationSlice";
import { Link, useNavigate } from "react-router-dom";
import useMediaQuery from "hook/useMediaQuery";
import MobileFooter from "./Mobile/MobileFooter";
// import MobileFooter from "./mobile footer/MobileFooter"
const Footer = ({ children }) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const matches = useMediaQuery("(min-width: 833px)");

  return (
    <div>
      {matches ? (
      <div className={Styles.Footer}>
        <div>
          <img
            src={Typography}
            alt="لوگوی دیاریس"
            width={75}
            className={Styles.Logo}
            onClick={() => {
              // dispatch(move(0));
              // router.push("/");
            }}
          />
        </div>
        <div>{children}</div>
        <div>
          <ul className={Styles.MenuAnimtion}>
            <li
              className={` ${Styles.AboutUs}`}
              onClick={() => {
                // dispatch(headerPage(null));
                navigate("/About Us");
              }}
            >
              <Link href="/About Us"> ارتباط با ما</Link>
            </li>
            <li
              className={` ${Styles.Order}`}
              onClick={() => {
                // dispatch(headerPage(null));
                navigate("/OrderRequest");
              }}
            >
              درخواست سفارش
            </li>
            <li
              className={` ${Styles.Jobs}`}
              onClick={() => {
                // dispatch(headerPage(null));
                navigate("/CareerOpportunities");
              }}
            >
              فرصت‌های <br /> شفلی
            </li>
          </ul>
        </div>
      </div>
      ) : (
        <div className="">
          <MobileFooter/>
        </div>
      )}
    </div>
  );
};

export default Footer;
