import React, { useEffect, useRef, useState } from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import Text from "assets/Text/diyaris/db.json";
import Style from "./Style.module.scss";
import useWindowSize from "hook/useWindowSize";
import { useChangeColor } from "zstand/BlackOrGreen";
import Footer from "Layout/Footer/Footer";
const MobileDiyaris = () => {
  const [active, setActive] = useState(null);
  const color = useChangeColor((state) => state.color);
  const firstSlideRef = useRef();
  const secondSlideRef = useRef();
  const threeSlide = useRef();
  const menu = Text.map((item) => item.title);
  const pagination = Text.map((item) => item.pagination);
  const myBody = Text.map((item)=>item.body)
  const BodyOne = Text.map((item) => item.pragragh)
  console.log("BodyOne",BodyOne)
  console.log("myBody",myBody)
  const activeBody = Text[active]?.body;
  const Paragraph = myBody.map((item) => {
    return item.map((subItem) => subItem.pragragh);
  });  
  console.log("pr",Paragraph)
  useEffect(() => {
    if (active === null) {
      setActive(0);
    }
  }, []);

  useEffect(() => {
    if (firstSlideRef.current && secondSlideRef.current) {
      firstSlideRef.current.sync(secondSlideRef.current.splide);
    }
  }, [firstSlideRef, secondSlideRef]);

  const handleSplide = (splide) => {
    setActive(splide.index);
    console.log("hi text")
  };
  return (
    <div>
      <div
        className={`w-[100vw]   ${
          color === "Black" ? "bg-black" : "bg-[#00c846]"
        } fixed top-20 h-16`}
      >
        <div
          className={`w-[14rem] h-16  mx-auto  rounded-full ${
            color === "Black" ? "opacity-0" : "text-opacity-100"
          }`}
        >
          <Splide
            ref={secondSlideRef}
            aria-label="Pagination"
            onMoved={handleSplide}
            className="text-black relative -top-10"
            options={{
              rewind: true,
              arrows: false,
              gap: "2px",
              type: "loop",
              direction: "rtl",
              perPage: 3,
              perMove: 1,
              focus: "center",
              pagination: false,
              wheel: true,
              omitEnd: true,
              speed: 1000,
              isNavigation: true,
              pauseOnFocus: true,
            }}
          >
            {pagination.map((title, index) => (
              <SplideSlide
                key={index}
                className={`rounded-full text-center max-w-fit ${
                  index === active
                    ? "border-[3px]   mx-2 h-12  border-[#00c846] font-bold text-[25px] px-2 py-1 "
                    : "text-[18px] px-4 py-2   hover:text-black text-black rounded-full"
                }`}
              >
                {title}
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
      {/* <div className="w-[100vw] fixed"> */}
      <div className={` h-16 fixed mt-[9rem] mx-auto bg-white  `}>
        <Splide
          ref={firstSlideRef}
          className="text-black  -top-10 w-[100vw]"
          aria-label="Menu"
          options={{
            rewind: true,
            arrows: false,
            gap: "2px",
            type: "loop",
            direction: "rtl",
            perPage: 1,
            perMove: 1,
            focus: "center",
            pagination: false,
            wheel: true,
            omitEnd: true,
            speed: 1000,
            isNavigation: true,
            pauseOnFocus: true,
          }}
          onMoved={handleSplide}
        >
          {menu.map((title, index) => (
            <SplideSlide
              key={index}
              className={`rounded-full text-center max-w-fit ${
                index === active
                  ? "border-[3px]  text-[#00c846] mx-2 h-12  border-[#00c846] font-bold text-[24px] px-2 py-1 "
                  : "text-[18px] px-4 py-2   hover:text-black text-gray-400 rounded-full"
              }`}
            >
              {title}
            </SplideSlide>
          ))}
        </Splide>
      </div>
      {/* step 2 */}
      <div
     className={` mx-auto relative top-[14rem] -z-20 text-[21px] text-white`}
      >
      <div className="text-[21px] " id="myContainer">
            {activeBody?.map((item, index) => (
              <div key={index}>
                <div className={`${Style.PragraghItem} px-4`}>
                  {item.head && <h1 className={Style.Head}>{item.head}</h1>}
                  <p className={Style.SubPragraph}>{item.pragragh}</p>
                  {item.list && (
                    <ul className={Style.List}>
                      {item.list.map((listItem, idx) => (
                        <li key={idx}>{Object.values(listItem)[0]}</li>
                      ))}
                    </ul>
                  )}
                  
                </div>
             
              </div>
            ))}
          </div>
      
          {active === 1 || active === 0 ? <p>
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                  </p> : null}
        <Footer />
      </div>
    </div>
  );
};

export default MobileDiyaris;
