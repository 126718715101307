import React, { useEffect, useState } from "react";
// For the first method, it is as follows
import Style from "./Style.module.css";
import Gate from "assets/LOGO/SVG/Gate.svg";
import LineDown from "assets/LOGO/SVG/LineDown.svg";
import CircleOuter from "assets/LOGO/SVG/CircleOuter.svg";
import CircleInner from "assets/LOGO/SVG/CircleInner.svg";
import LogoDiyaris from "assets/SVG/DSign.svg";
import LineUp from "assets/LOGO/SVG/LineUp.svg";
import Typography from "assets/LOGO/SVG/Typography.svg";
//import Image from "next/image";
//import { move } from "redux/Features/animation/animationSlice";

import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "hook/useMediaQuery";
import { useCountStore } from "zstand/animatin";
// import Style from "./Style.module.scss"
//For the first method, it is as follows
const LogoDyaris = () => {
  const anime = useCountStore((state) => state.count);
  const matches = useMediaQuery("(min-width: 433px)");
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (anime === "noAnime") {
      setCount(0);
    } else if (anime === "startAnime") {
      setCount(1);
    } else if (anime === "startBackAnime") {
      setCount(2);
    } else if (anime === "stopAnime") {
      setCount(3);
    }
  }, [anime]);
  let LogoAnimation;
  let LogoDiyarisAnimation;
  let LogoCircleOuter;
  let LogoCircleInner;
  let GateStyle;

  switch (count) {
    case 0:
      LogoAnimation = Style.LogoHome;
      LogoDiyarisAnimation = Style.Sign;
      LogoCircleOuter = Style.CircleOuter;
      LogoCircleInner = Style.CircleInner;
      GateStyle = Style.GateLogo;
      break;
    case 1:
      if (matches) {
        LogoAnimation = Style.AnimeLogoHome;
        LogoDiyarisAnimation = Style.UpSign;
        LogoCircleOuter = Style.UpCircleOuter;
        LogoCircleInner = Style.CircleInner;
        GateStyle = Style.AnimeLogoHiden;
        break;
      } else {
        LogoAnimation = Style.NotShowLogo;
        break;
      }

    case 2:
      if (matches) {
        LogoAnimation = Style.NotAnimeLogoHome;
        LogoDiyarisAnimation = Style.NotUpSign;
        LogoCircleOuter = Style.NotAnimeCircleOuter;
        LogoCircleInner = Style.NotAnimeCircleInner;
        GateStyle = Style.ShowWihoutAnime;
        break;
      } else {
        LogoAnimation = Style.NotShowLogo;
        break;
      }
    case 3:
      if (matches) {
        LogoAnimation = Style.BackLogoHome;
        LogoDiyarisAnimation = Style.BackSign;
        LogoCircleOuter = Style.BackCircleOuter;
        LogoCircleInner = Style.BackCircleInner;
        GateStyle = Style.BackGate;
        break;
      } else {
        LogoAnimation = Style.NotShowLogo;
        break;
      }
  }
  return (
    <div className={Style.Logo}>
      <div>
        <div className={LogoAnimation}>
          <div
            // onClick={handleBigAnimation}
            className={` ${count === 1 ? null : Style.DownSign}`}
          >
            <img
              src={CircleOuter}
              alt=" لوگوی دیاریس"
              className={LogoCircleOuter}
            />

            <img
              // onClick={handleBigAnimation}
              src={CircleInner}
              alt="لوگو دیاریس "
              className={LogoCircleInner}
            />
            <img
              // onClick={handleBigAnimation}
              src={LogoDiyaris}
              alt="Logo Diyaris"
              className={LogoDiyarisAnimation}
            />
          </div>
          <img src={Gate} alt="دیاریس" className={GateStyle} width={220} />
          {/* <div className={` ${count ? Style.AnimeLogoHiden : Style.AnimeLogo}`}> */}
          <div className="relative">
            <img
              src={LineUp}
              alt="دیاریس"
              width={220}
              className={` ${
                count === 1
                  ? Style.AnimeLogoHiden
                  : Style.AnimeLogoTypography
              } ${count === 2 && Style.ShowWihoutAnime} ${count === 3 ? Style.BackGate : Style.LineUp}`}
            />
            <img
              src={Typography}
              alt="typography Diyaris"
              width={220}
              className={`${count === 3 ? Style.BackGate : Style.Typography} ${
                count === 1
                  ? Style.AnimeLogoHiden
                  : Style.AnimeLogoTypography
              } ${count === 2 && Style.ShowWihoutAnime}`}
            />
            <img
              src={LineDown}
              alt=" دیاریس"
              width={220}
              className={` ${count === 3 ? Style.BackGate : Style.LineDown}  ${
                count === 1
                  ? Style.AnimeLogoHiden
                  : Style.LinUpAnime
              } ${count === 2 && Style.ShowWihoutAnime}`}
            />
          </div>

          {/* </div> */}
        </div>
      </div>
      {/* <div
        className={`${count === 3 || count === 0 ? Style.Pragraph : null} ${
          count === 1 || count === 2
            ? Style.AnimeLogoHiden
            : Style.AnimeLogoPragraph
        }`}
      >
        <p className={Style.PragraphFirstLine}>Development & Creation</p> <br />{" "}
        <p className={Style.PragraphTwoLine}>of Industry Identity</p>
      </div> */}
    </div>
  );
};

// But in another way, we want to place the logos inside each other

// const LogoDyaris = () =>{
//   return (
//     <div className={Style.LogoHome}>
//       <div className={Style.Gate}>
//         <div className={Style.CircleOuter}>
//           <div className={Style.CircleInner}>
//             <div className={Style.LogoDiyaris}>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//    )
// }
export default LogoDyaris;
