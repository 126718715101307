import React, { useRef, useState, useEffect } from "react";
import Style from "./Style.module.scss";
// import useScreenHeight from "hook/useScreenHeight";
import { useDispatch, useSelector } from "react-redux";
// import { scrollPage } from "redux/Features/scroll/scrollSlice";
// import { visiblePage } from "redux/Features/visible/visibleSlice";
import InputSms from "./Forms/InputSms";
import FormAboutUs from "./Forms/FormAboutUs";
import InputCode from "./Forms/InputCode";
import useWindowSize from "hook/useWindowSize";
import useMediaQuery from "hook/useMediaQuery";
import MobileAboutUs from "./MobileAboutUs/MobileAboutUs";
const AboutUs = () => {
  const matches = useMediaQuery("(min-width: 832px)");
  const [state, setState] = useState(0);
  const { width, height } = useWindowSize();

  //این هم برای تنظیم اندازه ای که کانیتر اطلاعات ما می گیرد تنظیم می شود
  const padingHeightBottom = height / 10;
  const handleSms = () => {
    setState(1);
  };
  const handleCode = () => {
    setState(2);
  };
  return (
    <div  dir="rtl">
      {matches ? (
        <div
          className={`${Style.Body} w-[75vw]  mx-auto relative top-9 bg-white overflow-y-auto rounded-2xl py-4 text-black  scroll-ml-6`}
          style={{ height: `${height - 70}px` }}
        >
          <p className="px-6">ارتباط با ما</p>
          <div className={`p-6`}>
            <p>
              دانستن نقطه نظرها، دیدگاه‌ها، پیشنهادها، و انتقادات شما برای گروه
              چینش حیاتی است. همچنین برای شروع کامل یک همکاری دقیق، لازم است شما
              تصویری از سازمان خود در ذهن ما ترسیم کنید. پس ما را در هر آنچه که
              باید بدانیم شریک بدانید و از هر آنچه که باید برای شما انجام دهیم
              مطلع سازید. پر کردن فرم‌های این صفحه بهترین روش مناسب است.. همراهی
              در تصویرها و تصورهای شما آرزوی چینش است.
            </p>
            <div className={Style.Form}>
              <p>
                در حال حاضر اگر مایل به ارائه اطلاعات کامل از سازمان خود نیستید
                و دوست دارید گروه چینش هر چه زودتر با شما ارتباط برقرار کند،
                لطفا مینی فرم زیر را پر کنید.
              </p>

              {state === 0 ? (
                <InputSms handleSms={handleSms} />
              ) : state === 1 ? (
                <InputCode handleCode={handleCode} />
              ) : (
                <FormAboutUs />
              )}
            </div>
          </div>
          {/*  */}
        </div>
      ) : (
        <div>
          <MobileAboutUs />
        </div>
      )}

      {/* <ScrollNavbar completion={completion} /> */}
    </div>
  );
};

export default AboutUs;
