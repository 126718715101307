import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import React, { useEffect, useRef, useState } from "react";
import Style from "./Style.module.scss";
import useWindowSize from "hook/useWindowSize";
import PageOne from "./components/Page One/PageOne";
import PageTwo from "./components/Page Two/PageTwo";
import PageThree from "./components/Page Three/PageThree";
import PageFore from "./components/Page Fore/PageFore";
import PageFive from "./components/Page Five/PageFive";
import useMediaQuery from "hook/useMediaQuery";
import { useChangeColor } from "zstand/BlackOrGreen";
import Footer from "Layout/Footer/Footer";
const MobileService = ({ Menu }) => {
  const { goBlack, goGreen } = useChangeColor((state) => state.actions);
  const color = useChangeColor((state) => state.color);
  console.log("color", color);
  const [active, setActive] = useState(null);
  const matches = useMediaQuery("(min-width: 435px)");
  const firstSlideRef = useRef();
  const secondSlideRef = useRef();
  //از این جا برای ثابت کردن اندازه استفاده می شود
  const { width, height } = useWindowSize();
  //این هم برای تنظیم اندازه ای که کانیتر اطلاعات ما می گیرد تنظیم می شود
  const padingHeightBottom = height / 2;
  useEffect(() => {
    if (active === null) {
      setActive(0);
    }
  }, [active]);
  useEffect(() => {
    if (firstSlideRef.current && secondSlideRef.current) {
      firstSlideRef.current.sync(secondSlideRef.current.splide);
    }
  }, [firstSlideRef, secondSlideRef]);
  const handleSplide = (splide) => {
    setActive(splide.index);
  };
  return (
    <div>
      <div
        className={`w-[100vw] bg-[#00c846] ${
          color === "Black" ? "bg-black" : "bg-[#00c846]"
        } fixed top-20 h-16`}
      >
        <div className={`w-[14rem] h-16  mx-auto  rounded-full`}>
          <Splide
            ref={secondSlideRef}
            aria-label="Pagination"
            onMoved={handleSplide}
            className="text-black relative -top-10"
            options={{
              rewind: true,
              arrows: false,
              type: "loop",
              gap: "2px",
              direction: "rtl",
              perPage: 3,
              perMove: 1,
              focus: "center",
              pagination: false,
              wheel: true,
              omitEnd: true,
              speed: 1000,
              isNavigation: true,
              pauseOnFocus: true,
            }}
          >
            {Menu.map((item, index) => (
              <SplideSlide
                key={index}
                className={`rounded-full text-center max-w-fit ${
                  index === active
                    ? "border-[3px]   mx-2 h-12  border-[#00c846] font-bold text-[24px] px-2 py-1 "
                    : "text-[18px] px-4 py-2   hover:text-black text-black rounded-full"
                }`}
              >
                {item.pagination}
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
      <div className="w-[100vw] fixed ">
        {matches ? (
          <div className={`w-[100%] h-16 mt-[9rem] mx-auto bg-white  `}>
            <Splide
              ref={firstSlideRef}
              className="text-black relative -top-9 w-[100vw]"
              aria-label="Menu"
              options={{
                rewind: true,
                arrows: false,
                type: "loop",
                // gap: "2px",
                direction: "rtl",
                perPage: 1,
                perMove: 1,
                focus: "center",
                pagination: false,
                wheel: true,
                omitEnd: true,
                speed: 1000,
                isNavigation: true,
                pauseOnFocus: true,
              }}
              onMoved={handleSplide}
            >
              {Menu.map((item, index) => (
                <SplideSlide
                  key={index}
                  className={`rounded-full text-center max-w-fit ${
                    index === active
                      ? "border-[3px]  text-[#00c846] mx-2 h-12  border-[#00c846] font-bold text-[24px] px-2 py-1 "
                      : "text-[18px] px-4 py-2   hover:text-black text-gray-400 rounded-full"
                  }`}
                >
                  {item.title}
                </SplideSlide>
              ))}
            </Splide>
          </div>
        ) : (
          <div className={`w-[150%] h-16  mt-[9rem] mx-auto bg-white  `}>
            <div className="relative -top-10">
              <Splide
                ref={firstSlideRef}
                className="text-black "
                aria-label="Menu"
                options={{
                  updateOnMove: true,
                  rewind: true,
                  arrows: false,
                  type: "loop",
                  gap: "1px",
                  direction: "rtl",
                  perPage:1 ,
                  preloadPages:8,
                  perMove: 1,
                  focus: 0,
                  pagination: false,
                  wheel: true,
                  omitEnd: true,
                  speed: 1000,
                  isNavigation: true,
                  pauseOnFocus: true,
                  // height:"3rem"
                }}
                onMoved={handleSplide}
              >
                {Menu.map((item, index) => (
                  <SplideSlide
                    key={index}
                    className={`rounded-full text-center max-w-fit ${
                      index === active
                        ? "border-[3px]  text-[#00c846] mx-2 h-12  border-[#00c846] font-bold text-[24px] px-2 py-1 "
                        : "text-[18px] px-4 py-2   hover:text-black text-gray-400 rounded-full"
                    }`}
                  >
                    {item.title}
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          </div>
        )}
      </div>
      <div className="w-full  mb-80" style={{height:`${height}px`}}>
        <div
          className={`${Style.Body}  mx-auto relative top-[14rem] -z-20 text-[21px] text-white`}
          id="myContainer"
          style={{ height: `${height - 370}px` }}
        >
          {active === 0 && <PageOne />}
          {active === 1 && <PageTwo />}
          {active === 2 && <PageThree />}
          {active === 3 && <PageFore />}
          {active === 4 && <PageFive />}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MobileService;
