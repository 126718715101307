import {create} from "zustand"; 


export const useCountStore = create((set)=>({
  count:"noAnime",
  actions:{
    goAnime:()=>{
        set((state)=>({...state,count: state.count  = "startAnime"}))
    },
    backAnime:()=>{
        set((state)=>({...state,count: state.count  = "startBackAnime"}))
    },
    stopAnime:()=>{
      set((state)=>({...state,count: state.count  = "stopAnime"}))
  } ,
    noAnime:()=>{
      set((state)=>({...state,count: state.count  = "noAnime"}))
    }
  }
}))