import { NewData } from "assets/Text/customer/Data";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Style from "./Style.module.scss";
import Diyaris from "assets/SVG/Monogram.svg";
import AABIII from "assets/SVG/AABIII.svg";
import Sign from "assets/SVG/Sign.svg";
import Circle from "assets/SVG/diyaris-cv-circle.svg";
import Squre from "assets/SVG/diyaris-aabiii-cv-square.svg";
import Footer from "Layout/Footer/Footer";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import useWindowSize from "hook/useWindowSize";
import Green from "assets/Customers/Logo/JPG/green.jpg";
import MobileDetails from "./Mobile/MobileDetails";
import useMediaQuery from "hook/useMediaQuery";
import useCoustomerFinder from "zstand/coustomerFinder";

const CustomerDetials = () => {
  const params = useParams();
  const [selectedLogo, setSelectedLogo] = useState(null);
  const navigate = useNavigate();
  const { value, findCustomer } = useCoustomerFinder();
  console.log("value", value);
  const { width, height } = useWindowSize();
  const padingHeightBottom = height / 6;
  // const history = useHistory();
  const foundCustomer = NewData?.find(
    (item) => item.Title === decodeURIComponent(params.id)
  );
  const findIdcustomer = NewData?.findIndex((item) => item.id === value);
  console.log("findIdcustomer", findIdcustomer);
  const [active, setActive] = useState(foundCustomer ? foundCustomer.id : 0);
  const matches = useMediaQuery("(min-width: 832px)");
  useEffect(() => {
    const foundCustomer = NewData.find(
      (item) => item.Title === decodeURIComponent(params.id)
    );
    if (foundCustomer) {
      // اگر لوگوی انتخاب شده با لوگوهای موجود همخوانی داشته باشد
      if (NewData.some((item) => item.Logo === foundCustomer.Logo)) {
        setSelectedLogo(foundCustomer.Logo);
      }
    }
  }, [params.id]);
  const firstSlideRef = useRef();
  const secondSlideRef = useRef();
  useEffect(() => {
    if (firstSlideRef.current && secondSlideRef.current) {
      firstSlideRef.current.sync(secondSlideRef.current.splide);
    }
  }, [firstSlideRef, secondSlideRef]);
  const handleSplide = (splide) => {
    setActive(splide.index);
    findCustomer(splide.index);
    const findTitle = NewData?.find((item) => item.id === splide.index + 1);
    navigate(`/Customer/${findTitle.Title}`);
  };
  return (
    <div>
      {matches ? (
        <div>
          <div className="bg-white w-[75%]  py-3 flex flex-row h-[4.2rem]  mx-auto rounded-full  mt-4">
            <div className=" relative -top-[3.59rem] ">
              <Splide
                aria-label="My Favorite Images"
                ref={firstSlideRef}
                onMoved={handleSplide}
                className=""
                options={{
                  rewind: true,
                  arrows: false,
                  gap: "-0.8rem",
                  type: "loop",
                  direction: "rtl",
                  speed: 1000,
                  perPage: 8,
                  perMove: 1,
                  focus: "center",
                  pagination: false,
                  wheel: true,
                  omitEnd: true,
                  isNavigation: true,
                  // start: `${findIdcustomer}`,
                  breakpoints: {
                    600: {
                      perPage: 3,
                      // height: "5rem",
                    },
                  },
                }}
              >
                {NewData.map((item, index) => (
                  <SplideSlide key={index} className="">
                    <div className={`${selectedLogo === index ? "" : ""}`}>
                      {index === active ? (
                        <div
                          style={{
                            // width: "60px",
                            height: "58px",
                            WebkitMaskImage: `url(${item.LogoSvg})`,
                            maskImage: `url(${item.LogoSvg})`,
                            maskRepeat: "no-repeat",
                            maskPosition: "center",
                            marginLeft: "auto",
                            marginTop: "auto",
                          }}
                        >
                          <img
                            src={Green}
                            alt="Logo"
                            className={`${Style.Mask} mx-auto w-full h-[70px]  `}
                          />
                        </div>
                      ) : (
                        <img
                          src={item.LogoSvg}
                          alt="Logo"
                          className={`mx-auto  h-[45px] opacity-30 `}
                        />
                      )}
                    </div>
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          </div>
          <div
            className={`${Style.DetailsCustomer} mx-auto rounded-2xl pb-6`}
            style={{ height: `${height - 150}px` }}
            dir="rtl"
          >
            {/* نام برند و نام شرکت و اطلاعات ابتدایی پروژه */}
            {foundCustomer && foundCustomer.Title ? (
              <div className={`${Style.Text} p-4`}>
                <div className={Style.Conainers}>
                  <p className={`${Style.SmallContainer} rounded-md mt-1`}>
                    برند: {foundCustomer.Title}
                  </p>
                  <p className={`${Style.SmallContainer} rounded-md mt-1`}>
                    نام شرکت: {foundCustomer.Compony}
                  </p>
                  <p className={`${Style.SmallContainer} rounded-md mt-1`}>
                    تاریخ شروع فعالیت: {foundCustomer.Start}
                  </p>
                  <p className={`${Style.SmallContainer} rounded-md mt-1`}>
                    تاریخ پایان فعالیت: {foundCustomer.Finish}
                  </p>
                </div>
                {/* معرفی شرکت  */}
                <div className={Style.explanation}>
                  <h2 className="text-[21px] font-bold">معرفی شرکت</h2>
                  <p>{foundCustomer.Introduction}</p>
                </div>
                {/* معرفی و شرح کالا و پروژه */}
                <div className={Style.explanation}>
                  <h2 className="text-[21px] font-bold">شرح کلا پروژه</h2>
                  <p>{foundCustomer.DescriptionOfProjects}</p>
                </div>
                {/*کانتینر  های دیاریس و آبی  */}
                <div className="flex flex-row    justify-between  items-start   mt-5   ">
                  {/* کانتینر دیاریس */}
                  {foundCustomer.DiyarisList?.length > 0 && (
                    <div className="w-full ">
                      <div className="border-2 border-[#00c846] w-full p-2 bg-[#00c846]  rounded-t-2xl  flex  items-center flex-row justify-between h-16">
                        <img
                          src={Diyaris}
                          alt="Diyaris"
                          className="ml-2"
                          width={50}
                        />
                        <div>
                          <p className="text-white text-lg ">
                            اجزای عملیاتی پروژه در دیاریس
                          </p>
                        </div>
                      </div>
                      <div className="border-[3px] -t-1 relative w-full border-[#00c846] rounded-b-2xl">
                        <div className={Style.DiyaricPragraph}>
                          <ul>
                            {foundCustomer.DiyarisList?.map(
                              (diyarisItem, index) => (
                                <li key={index}>
                                  <div className="flex flex-row">
                                    {" "}
                                    {/* <img src={Square} alt="Square" width={15} />{" "} */}
                                    <h1 className="font-bold">
                                      {diyarisItem.head}
                                    </h1>{" "}
                                  </div>
                                  <ul className="pt-1 pb-2">
                                    {diyarisItem.items?.map(
                                      (item, itemIndex) => (
                                        <li
                                          key={itemIndex}
                                          className="flex flex-row"
                                        >
                                          <img
                                            src={Circle}
                                            alt="Circle"
                                            width={13}
                                            className="pr-1"
                                          />
                                          <p className="text-lg pr-1">{item}</p>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* کانتینر خانه آبی  */}
                  {foundCustomer.AabiiiList?.length > 0 && (
                    <div className="w-full  mr-4 ">
                      <div
                        className="  border-2  w-full border-blue-700 p-2 bg-blue-700 
              rounded-t-2xl  flex flex-row-reverse justify-between items-center h-16"
                      >
                        <div className="flex flex-row-reverse flex-wrap ">
                          <img src={Sign} alt="Sign" width={20} />
                          <p className="text-lg ml-2 text-white">
                            اجزای عملیاتی پروژه در استودیو طراحی آبی
                          </p>
                        </div>
                        <img
                          src={AABIII}
                          alt="AABIII"
                          width={50}
                          className="ml-2"
                        />
                      </div>
                      <div className="border-[3px] w-full  border-blue-700 rounded-b-2xl max-lg:mt-4">
                        <div className={Style.AbiiiPragraph}>
                          <ul>
                            {foundCustomer.AabiiiList?.map(
                              (aabiiiList, index) => (
                                <li key={index}>
                                  <div className="flex flex-row">
                                    {" "}
                                    {/* <img src={Square} alt="Square" width={15} />{" "} */}
                                    <h1 className="font-bold">
                                      {aabiiiList.head}
                                    </h1>{" "}
                                  </div>
                                  <ul>
                                    {aabiiiList.items?.map(
                                      (item, itemIndex) => (
                                        <li
                                          key={itemIndex}
                                          className="flex flex-row"
                                        >
                                          {" "}
                                          <img
                                            src={Squre}
                                            alt="Squre"
                                            width={13}
                                            className="pr-1"
                                          />{" "}
                                          <p className="text-lg pr-1">{item}</p>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* <Picker/> */}
              </div>
            ) : (
              <div>مشتری پیدا نشد.</div>
            )}
          </div>
          <Footer>
            <div className={`${Style.Pagination} rounded-full`}>
              <Splide
                ref={secondSlideRef}
                aria-label="Pagination"
                // onMoved={handleSplide}
                hasTrack={false}
                options={{
                  rewind: true,
                  arrows: false,
                  type: "loop",
                  direction: "ttb",
                  rewindByDrag: true,
                  speed: 1000,
                  perPage: 3,
                  perMove: 1,
                  height: "8rem",
                  focus: "center",
                  pagination: false,
                  wheel: true,
                  omitEnd: true,
                  // start: `${foundCustomer.id}`,
                  isNavigation: true,
                  breakpoints: {
                    600: {
                      perPage: 3,
                      height: "5rem",
                    },
                  },
                }}
              >
                <SplideTrack>
                  {NewData.map((item, index) => (
                    <SplideSlide
                      key={index}
                      className={`${
                        index === active
                          ? ` border-[3px] w-14 font-bold text-[21px] text-[#00c846] mx-2 px-1 text-center py-1  border-[#00c846]  rounded-full`
                          : `text-[18px]   py-2  text-gray-400 rounded-full`
                      }`}
                    >
                      {item.Start}
                    </SplideSlide>
                  ))}
                </SplideTrack>
              </Splide>
            </div>
          </Footer>
        </div>
      ) : (
        <div>
          <MobileDetails />
        </div>
      )}
    </div>
  );
};

export default CustomerDetials;
