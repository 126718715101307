import React from "react";
import Style from "./Style.module.scss"
import { useCountStore } from "zstand/animatin";
const Text = () => {
  const anime = useCountStore((state) => state.count);
  return (
    <div>
      <div
        className="text-center text-[23px]  pt-[7%] pb-[3%]"
      >
        <p
          className="text-white"
        >
          <span className={anime === "startBackAnime" ? Style.JustShow :  Style.LineOne}>Development & Creation</span>  <br />
          <span className={anime === "startBackAnime" ? Style.JustShow : Style.LineTwo}>of Industry Identity</span>  
        </p>
      </div>
    </div>
  );
};

export default Text;
