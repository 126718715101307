import React, { useEffect, useState } from "react";
import PartOne from "./part one/PartOne";
import PartTwo from "./part two/PartTwo";
import InputSms from "./InputSms";
import InputCode from "./InputCode";

const Form = ({handleBigContainer}) => {
  const [state, setstate] = useState(0);
  const [sms, setSms] = useState(0);

  const handelNextPage = () => {
    setstate(1);
  };
  const handelBackPage = () => {
    setstate(0);
  };
  useEffect(() => {
    handleBigContainer();
  }, [state]);
  // const handleSms = () => {
  //   setSms(1);
  // };
  // const handelCode = () => {
  //   setSms(2);
  // };
  return (
    <div>
      {/* {sms === 0 ? (
        <InputSms handleSms={handleSms} />
      ) : sms === 1 ? (
        <InputCode handelCode={handelCode} />
      ) : ( <p>hi</p>)} */}
        <div>
          {state === 0 ? (
            <PartOne nextPage={handelNextPage} />
          ) : (
            <PartTwo backPage={handelBackPage} />
          )}
        </div>
      {/* )} */}
    </div>
  );
};

export default Form;
