import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './Features/counter/counterSlice';
import  animationReducer from './Features/animation/animationSlice'
import lodingSlice from './Features/loding/lodingSlice';
import headerReducer from './Features/Header/headerSlice';
import scrollSlice from './Features/scroll/scrollSlice';
import visibleSlice from './Features/visible/visibleSlice';
import searchSlice  from './Features/search/SearchSlice';
import newAnimeSlice from "./Features/newAnime/newAnimeSlice";
export default configureStore({
  reducer: {
    counter: counterReducer,
    newAnime: newAnimeSlice,
    animation: animationReducer,
    loding:lodingSlice,
    header: headerReducer,
    scroll : scrollSlice,
    visible : visibleSlice,
    search: searchSlice,
  },
});