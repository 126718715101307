import React from "react";
import Style from "./Style.module.css";

const PageTwo = () => {
 
  
  return (
    <div className={Style.PageTwo}>
      
      <div className={Style.Cloum}>
        <h1 className={Style.H1}>۰۱ - راهبرد سطح سازمان یا شرکت Corporate Level Strategy</h1>
        <p className={Style.Pragragh}>
          راهبرد در سطح سازمان که با نام راهبرد شرکت شناخته می‌شود، در تعیین
          اهداف سالانه‌ی شرکت تأثیر بسزایی دارد و در بالاترین سطح شرکت اجرا
          می‌شود. راهبرد با گام‌های مختلف برای کارکنان یک سازمان حیاتی است. با
          طرح راهبردی می‌توان مسیری که قرار است در آینده به آن رسید را برای
          کارکنان سازمان با همراهی بیشتر روشن نمود.
        </p>
        <p className={Style.Pragragh}>
          طرح‌های راهبردی یک شرکت، برنامه‌هایی با سطح بندی مختلف از یک شرکت است
          که مدیران ارشد برای تعیین، تعریف و دستیابی به اهداف کلان آن شرکت، به
          آن نیاز دارند.
        </p>
        <h2 className={Style.H2}>انواع راهبرد در سطح سازمانی</h2>
        <p>۰۱ - راهبرد ثبات</p>
        <p>۰۲ - راهبرد توسعه</p>
        <p>۰۳ - راهبرد کاهش یا اصلاحات ساختاری</p>
      </div>
      <div className={Style.Cloum}>
        <p>0۴ - راهبرد تلفیق</p>
        <h2 className={Style.H2Special}>02 - راهبرد سطح واحدهای کسب و کار Business level strategy</h2>
        <p className={Style.Pragragh}>
          راهبرد در سطح واحدهای کسب‌و‌کار از طریق طرحی با روش پیاده‌سازی بر روی
          چشم انداز یک شرکت با دستورالعمل‌های متمرکز بر سطح کسب و کار میسر
          می‌شود.
        </p>
        <p className={Style.Pragragh}>
          راهبرد در سطح کسب و کار، بر روی بهبود موقعیت رقابتی محصولات یک سازمان،
          اعم از کالا و یا خدمات تمرکز دارد.
        </p>
        <h2 className={Style.H2}>انواع راهبردهای در سطح واحدهای کسب و کار</h2>
        <p className={Style.PragraghSpecial}>۰۱ - راهبرد توسعه</p>
        <p className={Style.PragraghSpecial}>۰۲ - راهبرد رقابتی</p>
        <p className={Style.PragraghSpecial}>۰۳ - راهبرد مشارکتی یا ائتلاف‌های راهبردی</p>
        <h2 className={Style.H2}>03 - راهبرد سطح عملکرد یا وظیفه‌ای Functional level strategy </h2>
      </div>
      <div className={Style.Cloum}>
        <p className={Style.Pragragh}>
          این راهبرد بر وظایف فردی هر واحد و منابع انسانی و همچنین تلاش برای
          دستیابی به اهداف شرکت تمرکز دارد. دیدگاه راهبرد وظیفه‌ای این است که با
          به حداکثر رساندن بهره‌وری منابع، راه را برای دستیابی به اهداف و
          راهبردهای کل شرکت هموار می‌کند.
        </p>
        <p className={Style.Pragragh}>
          اگر بخواهیم تعریفی از منطقه‌ی اصلی اجرای راهبرد عملکرد داشته باشیم،
          می‌توانیم در واحدهای مجزای یک شرکت که هر کدام از آن واحدها خط مشی و
          راهبرد مختص خود را دنبال می کنند، طرح راهبردی را در آنجا بیابیم.
        </p>
        <h2 className={Style.H2}>انواع راهبردهای سطح عملکرد یا وظیفه‌ای</h2>
        <p className={Style.PragraghSpecial}>۰۱ - راهبرد بازاریابی و فروش</p>
        <p className={Style.PragraghSpecial}>۰۲ - راهبرد تولید و عملیات</p>
        <p className={Style.PragraghSpecial}>۰۳ - راهبرد امور مالی و حسابداری</p>
        <p className={Style.PragraghSpecial}>۰۴ - راهبرد سیستم های اطلاعاتی</p>
        <p className={Style.PragraghSpecial}>۰۵ - راهبرد تحقیق و توسعه</p>
      </div>
      
    </div>
  );
};

export default PageTwo;
