import React, { useState } from "react";
import Style from "./Style.module.scss";
import Form from "./components/form/Form";
import useWindowSize from "hook/useWindowSize";
import Footer from "Layout/Footer/Footer";

const MobileCaree = () => {
  const [state, setState] = useState(false);
  const { width, height } = useWindowSize();
  //این هم برای تنظیم اندازه ای که کانیتر اطلاعات ما می گیرد تنظیم می شود
  const padingHeightBottom = height / 10;
  const handleBigContainer = () => {
    if (state === false) {
      setState(true);
    } else {
      setState(false);
    }
  };
  return (
    <div>
      <div
        className={`  mx-auto relative top-[10rem]  -z-20 text-[21px] text-white`}
        style={{ height: `${height - 370}px` }}
      >
        {/* <p>همکاری با دیاریس</p> */}

        <div className="px-4">
          <p>
            همکاری واژه‌ای پایدار در کشورهای در حال توسعه و توسعه یافته است.
          </p>
          <p>
            این نگاه زمانی به عنوان محرک جامعه نمود پیدا می‌کند که تمام اعضای آن
            جامعه با ایمان به رشد و توسعه به بیداری جامعه فکر کنند.
          </p>
          <div className={Style.Form}>
            <p>
              برای چینش سبب خرسندی خواهد بود که دایره‌ی کارشناسانی که با این
              مجموعه همراه می شوند هر روز گسترش دهد. افرادی که با دیدگاهی درخشش
              یافته از باوری عمیق به ارزش های انسانی و قدرت گرفته از اعتماد به
              توانایی خویش، قواعد تجلی مهارت و تخصص خویش را در قالب تیم های
              تخصصی و محدوده های زمانی به خوبی می شناسند. در صورتی که چنین باور
              درخشان و مهارتی دارید، حتما با وارد نمودن اطلاعات خواسته شده، به
              ما بپیوندید.
            </p>
            <Form handleBigContainer={handleBigContainer} />
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  );
};

export default MobileCaree;
