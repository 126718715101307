import React from "react";
import Chart from "assets/PNG/DiagramOrganization.png";
import Style from "./Style.module.css";
const PageOne = () => {
  return (
    <div className={Style.PageOne}>
      <div className={Style.CloumOne}>
        <p>
          منابع انسانی، مالی، تکنولوژی، سیستم‌های اطلاعاتی و منابع فروش به عنوان
          شکل و موجودیت دهنده اصلی سازمان‌ها می‌باشند. این منابع در یک سازمان به
          سه ستون اصلی متصل می‌شوند.
        </p>
        <p className={Style.Pragragh}>
          • ساختار سازمانی <br /> • فرآیندهای سازمانی <br /> • سرمایه‌های انسانی
        </p>
        <p>
          پرداختن به این سه موضوع به عنوان منابع اثرگذار و بهینه یک سازمان نقش
          دارد.
        </p>
        <img src={Chart} alt="Chart image services" width={350} className="mr-auto ml-auto" />
      </div>
      <div className={Style.CloumTwo}>
        <p>
          در طراحی ساختار کلان سازمانی، انشعاب بین ظرفیت سازمان با تعامل منطقی
          ویژگی‌های محیط از طرفی و از سوی دیگر نگاه یک مدیر در سازمان به الزامات
          مفهومی آن سازمان، رعایت می‌شود. این ساختار به صورت کلان در دستیابی
          اهداف کلان سازمان‌ها کمک می‌کند.
        </p>
        <h2 className={Style.Title}>انواع ساختار سازمانی</h2>
        <p className={Style.Sub}>
          تنوع مختلف سازمان‌ها به نسبت ابعاد، اندازه، تنوع تکنولوژی، حوزه فعالیت
          و تنوع استفاده از منابع انسانی عامل انتخاب و طراحی مدل بهینه ساختار
          کلان سازمانی می‌شود. در زیر انواع مختلف ساختار سازمانی معرفی می‌شود
        </p>
      </div>
      <div className={Style.CloumThree}>
        <p className={Style.ItemsTop}>
          • سازمان بر اساس محصول (کالا / خدمات) <br />
          • سازمان بر اساس منطقه‌ی عملیات (جغرافیایی) <br />
          • سازمان ترکیبی یا چندگانه (هیبریدی) <br />
          • سازمان بر اساس وظیفه <br />• سازمان خزانه‌ای یا ماتریسی
        </p>
        <h2 className={Style.Title}>ترتیب انجام پروژه</h2>
        <p className={Style.ItemsBottom}>
          ۰۱ – انجام مصاحبه و جمع‌آوری اطلاعات پایه‌ای <br />
          ۰۲ – شناسایی فرآیندهای کاری <br />
          ۰۳ – تدوین ساختار کلان سازمان Top Chart <br />
          ۰۴ – اصلاح فرآیندهای گذشته <br />
          ۰۵ – تدوین ساختار تفصیلی و شرح مشاغل <br />
          ۰۶ – تهیه گزارش نهایی به همراه مستندات <br />
        </p>
      </div>
    </div>
  );
};

export default PageOne;
