import {create} from "zustand"; 


export const useShowMenuMobile = create((set)=>({
  shwoMenu:"DontShow",
  actions:{
    show:()=>{
        set((state)=>({...state,shwoMenu: state.shwoMenu  = "Show"}))
    },
    dontShow:()=>{
        set((state)=>({...state,shwoMenu: state.shwoMenu  = "DontShow"}))
    },
  }
}))