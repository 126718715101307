import Style from "./Menu.module.scss";
import React, { useEffect, useState } from "react";
// import Image from "next/image";
import Chinesh from "assets/SVG/Horizental.svg";
// import Link from "next/link";
import LogoDyaris from "../logo home/LogoDyaris";
// import { move } from "redux/Features/animation/animationSlice";
import ChinshWhite from "assets/SVG/Chinesh - Horizental (White).svg";
import { Link, useNavigate } from "react-router-dom";
import TextDiyaris from "../Text/Text";
import { useDispatch } from "react-redux";
import { useCountStore } from "zstand/animatin";
import { useShowMenuMobile } from "zstand/ShowMenuMobile";
// import { headerPage } from "redux/Features/Header/headerSlice";
const MenuHome = ({ showHeader }) => {
  const anime = useCountStore((state) => state.count);
  const [hoverLogo, setHoverLogo] = useState(false);
  const dispatch = useDispatch();
  // const count = useSelector((state) => state.animation.value);
  const [count, setCount] = useState(0);
  // const handleBigAnimation = () => {
  //  dispatch(headerPage(1));
  // };
  const { show ,dontShow} = useShowMenuMobile((state) => state.actions);
  useEffect(() => {
    if (anime === "noAnime") {
      setCount(0);
    } else if (anime === "startAnime") {
      setCount(1);
    } else if (anime === "startBackAnime") {
      setCount(2);
    } else if (anime === "stopAnime") {
      setCount(3);
    }
  }, [anime]);
  let Logo;
  let GreanCountainer;
  let Text;
  const navigate = useNavigate();
  const handleHoverLogo = () => {
    setHoverLogo(true);
  };
  const notHaverLogo = () => {
    setHoverLogo(false);
  };
  switch (count) {
    case 0:
      Logo = Style.GoUp;
      Text = Style.ShowText;
      GreanCountainer = Style.ContainerGreen;
      break;
    case 1:
      Logo = Style.Up;
      GreanCountainer = Style.NotShow;
      break;
    case 2:
      Logo = Style.NotGoUp;
      GreanCountainer = Style.ShowWihoutAnime;
      break;
    case 3:
      GreanCountainer = Style.BackContainerGreen;
      break;
  }
  return (
    <div className={Style.HomeMenu}>
      <div className={Logo}>
        <LogoDyaris />
      </div>
      <div className={Text}>
        <TextDiyaris />
      </div>
      {/* {countainer === true && ( */}
      <div className={Style.ChineshHome}>
        <div className={GreanCountainer}>
          <div className={ count === 2 ? Style.ShowMenuWithoutAnime : null}>
            <ul
              className={`${Style.MenuHome} ${
                 Style.TextHomePage
              } `}
            >
              <li
                className={`${count !== 2 && Style.MenuFrist1} ${
                  count === 2 && Style.MenuFrist1
                }`}
                onClick={() => {
                  showHeader();
                  show();
                }}
              >
                بینـــــــش{" "}
              </li>
              <li
                className={`${count !== 2 && Style.MenuFrist2} ${
                  count === 2 && Style.MenuFrist2
                }`}
                onClick={() => {
                  navigate("/diyaris");
                }}
              >
                دیاریــــس
              </li>
              <li
                className={`${count !== 2 && Style.MenuFrist3} ${
                  count === 2 && Style.MenuFrist3
                }`}
                onClick={() => {
                  navigate("/services");
                }}
              >
                خدمـــــات
              </li>
              <li
                className={`${count !== 2 && Style.MenuFrist4} ${
                  count === 2 && Style.MenuFrist4
                }`}
                onClick={() => {
                  navigate("/Customer");
                }}
              >
                مشـــتریان
              </li>
            </ul>
            <div
              className={Style.LogoAnimation}
              onMouseEnter={handleHoverLogo}
              onMouseLeave={notHaverLogo}
            >
              <a href="https://chinesh.com/" target="_blank">
                {" "}
                <img
                  src={Chinesh}
                  alt="لوگوی چینش "
                  className={hoverLogo === true ? Style.NotShowLogo : null}
                />
                <img
                  src={ChinshWhite}
                  alt="لوگوی چینش سفید"
                  className={hoverLogo === true ? null : Style.NotShowLogo}
                />
              </a>
            </div>
          </div>
          {/* stpe 2*/}
        </div>
      </div>

      {/* )} */}
    </div>
  );
};

export default MenuHome;
