import React from "react";
import Style from "./Style.module.css";
const PageFore = () => {
  return (
    <div className={Style.PageFore}>
      <div className={Style.Cloum}>
        <p className={Style.Pragragh}>
          نام تجاری با چند رویکرد در سازمان‌ها خود را به نمایش می‌گذارد. نام
          سازمان، نام محصول یا خدمت، نام واحد و یا نام‌های دیگری که از یک سازمان
          ارائه می‌شود.
        </p>
        <p className={Style.Pragragh}>
          هر کدام از این نام‌ها بر اساس و پایه‌گذاری یک سیستم، در غالب و روشی
          خاص از خود مفهومی را به بازار انتقال می‌هند. نتیجه‌ی اثربخشی این
          انتقال به ماندگاری و سابقه‌ی یک نام در آن بازار ارزش می‌گذارد.
        </p>
        <p className={Style.Pragragh}>
          بر اساس اهمیت مطرح شده در پیکره این اثر، پیرامون زیست یک نام شکل
          می‌گیرد. از اینرو چگونگی ساختار و سازوکار طراحی یک نام تجاری به معماری
          و زیست بوم ویژه‌ای نیازمند است.
        </p>
        <p className={Style.Pragragh}>
          شرکت دیاریس در برنامه‌ریزی و طراحی معماری نام تجاری یک سازمان، نگاه
          پیشرو و خلاقانه‌ای
        </p>
      </div>
      <div className={Style.Cloum}>
        <p className={Style.Pragragh}>
          را ارائه می‌کند. ما با رویارویی تصویر در محیط بازار و مجموعه عوامل
          اثربخش در آن بازار، به همراه فرهنگ و گذشته‌ی مخاطبان آن سازمان، روشی
          را برای همزیستی با آنها در کنار هم محیی می‌کنیم.
        </p>
        <h2>انواع معماری موجود در بازار جهانی</h2>
        <div>
          <p className={Style.PragraghSpecial}>۰۱ - مدل خانه نام تجاری (Branded House)</p>
          <p className={Style.PragraghSpecial}>
          ۰۲ - مدل خانه نام‌های تجاری یا خانواده نام‌های تجاری (House of
            Brands)
          </p>
          <p className={Style.PragraghSpecial}>۰۳ - مدل نام تجاری حمایت شده یا تاییدشده (Endorsed)</p>
          <p className={Style.PragraghSpecial}>۰۴ - مدل نام تجاری ترکیبی</p>
          <p className={Style.PragraghSpecial}>۰۵ - مدل انعطاف پذیر نام تجاری</p>
        </div>
        <h2>مراحل تدوین و طراحی معماری نام تجاری</h2>
      </div>
      <div>
        <div>
            <p className={Style.PragraghSpecial}>۰۱ - تعیین ذینفعان سازمانی نام تجاری در آن شکل می‌گیرد</p>
            <p className={Style.PragraghSpecial}>۰۲ - تعیین ارزش‌ها و ماموریت سازمان </p>
            <p className={Style.PragraghSpecial}>۰۳ - تشخیص ارزش نام‌های تجاری وابسته و نام‌های تجاری فرعی نام اصلی </p>
            <p className={Style.PragraghSpecial}>۰۴ - تشخیص ارزش در روابط نام‌های تجاری مرتبط و مشترک </p>
            <p className={Style.PragraghSpecial}>۰۵ - تعیین فرآیند و هزینه‌های بازاریابی </p>
            <p className={Style.PragraghSpecial}>۰۶ - تشخیص و ارزیابی پیامدهای قانونی یا مالیاتی </p>
            <p className={Style.PragraghSpecial}>۰۷ - تدوین راه‌کارهای زمانبندی برای معرفی معماری نام‌ تجاری </p>
            <p className={Style.PragraghSpecial}>۰۸ - تدوین و ترسیم درخت‌واره معماری نام‌ تجاری برای تشخیص بهتر ادامه مسیر</p>
        </div>
      </div>
    </div>
  );
};

export default PageFore;
