import {create} from "zustand"; 


export const useChangeColor = create((set)=>({
  color:"Green",
  actions:{
    goBlack:()=>{
        set((state)=>({...state,color: state.color  = "Black"}))
    },
    goGreen:()=>{
        set((state)=>({...state,color: state.color  = "Green"}))
    },
  
  }
}))