// step 01
import React, { useRef, useState } from "react";
import { useSpring, useTrail, animated } from "react-spring";
import Style from "./Style.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { NewData } from "assets/Text/customer/Data";
import Marquee from "react-fast-marquee";
import useMediaQuery from "hook/useMediaQuery";
import useCoustomerFinder from "zstand/coustomerFinder";
import { useChangeColor } from "zstand/BlackOrGreen";
import Footer from "Layout/Footer/Footer";

const ActLogos = () => {
  const containerRef = useRef(null);
  const { value, findCustomer } = useCoustomerFinder();
  const color = useChangeColor((state) => state.color);
  const [isHovered, setIsHovered] = useState(true);
  const navigate = useNavigate();
  console.log("Customer", NewData);
  // const logosPerSection = Math.ceil(NewData.length / 3);

  // Calculate the number of logos in each section
  const logosPerSection = Math.ceil(NewData.length / 3);

  // Slice the NewData array into three sections
  const LogosLineOne = NewData.slice(0, logosPerSection);

  const LogosLineTwo = NewData.slice(logosPerSection, 2 * logosPerSection);

  const LogosLineThree = NewData.slice(2 * logosPerSection);
  const matches = useMediaQuery("(min-width: 832px)");
  const handleLogoClick = (props) => {
    NewData.forEach((item) => {
      if (item.Title === props.Title) {
        navigate(`/Customer/${item.Title}`);
        findCustomer(item.id);
      }
    });
  };
  return (
    <div className={``}>
      {matches === false && (
        <div>
          <div
            className={`w-[100vw] z-10 ${
              color === "Black" ? "bg-black" : "bg-[#00c846]"
            } fixed top-20 h-16`}
          >
            {" "}
            <p className={`text-[21px] text-center py-4 ${color === "Black" ? "opacity-0": "opacity-100"}`}>انتخاب کنید</p>
          </div>
        </div>
      )}
      <div
        className={`${
          matches ? "py-[2%]" : `${Style.LogoMobile}`
        }  w-full  flex flex-col justify-center content-center `}
      >
          <Marquee  pauseOnHover={true} speed={50}>
            {LogosLineOne.map((props, index) => (
              <div
                key={index}
                className="mx-2 bg-white rounded-xl cursor-pointer"
              >
                <img
                  src={matches ? props.Logo : props.LogoS}
                  alt="Logo"
                  className="px-4 "
                  onClick={() => handleLogoClick(props)}
                />
              </div>
            ))}
          </Marquee>
        <Marquee className="mt-4" pauseOnHover={true} speed={60}>
          {LogosLineTwo.map((props, index) => (
            <div
              key={index}
              className="mx-2 bg-white rounded-xl cursor-pointer"
            >
              <img
                src={matches ? props.Logo : props.LogoS}
                className=" px-4"
                alt="Logo"
                // className="h-14"
                onClick={() => handleLogoClick(props)}
              />
            </div>
          ))}
        </Marquee>
        <Marquee className="mt-4" pauseOnHover={true} speed={70}>
          {LogosLineThree.map((props, index) => (
            <div
              key={index}
              className="mx-2 bg-white rounded-xl cursor-pointer"
            >
              <img
                src={matches ? props.Logo : props.LogoS}
                alt="Logo"
                className="px-4"
                onClick={() => handleLogoClick(props)}
              />
            </div>
          ))}
        </Marquee>
        
      </div>
      {/* {matches === false && <div className="fixed bottom-32 "><Footer/></div>} */}
    </div>
  );
};

export default ActLogos;
