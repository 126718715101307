import { createSlice } from '@reduxjs/toolkit'
// 0 : stop animation
// 1 :go animation
// 2 : not animation
// 3 : back animation
const initialState = {
  value: 0 ,
}

export const animationSlice = createSlice({
  name: 'move',
  initialState,
  reducers: {
    move:(state,action)=>{
      state.value = action.payload
    },
   
  },
})

export const {move} = animationSlice.actions
export default animationSlice.reducer