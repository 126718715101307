import React, { useEffect, useRef, useState } from "react";
import Style from "./Style.module.scss";
import Form from "./components/form/Form";
import { useDispatch, useSelector } from "react-redux";
// import { scrollPage } from "redux/Features/scroll/scrollSlice";
import useScreenHeight from "hook/useScreenHeight";
import useWindowSize from "hook/useWindowSize";
import useMediaQuery from "hook/useMediaQuery";
import MobileOrder from "./Mobile/MobileOrder";

const OrderRequest = () => {
  const scrollBody = useSelector((state) => state.scroll.value);
  const dispatch = useDispatch();
  const containerRef = useRef(null); // ایجاد ref برای کانتینر
  const [state, setState] = useState(false);
  const screenHeight = useScreenHeight();
  //از این جا برای ثابت کردن اندازه استفاده می شود
  const { width, height } = useWindowSize();
  //این هم برای تنظیم اندازه ای که کانیتر اطلاعات ما می گیرد تنظیم می شود
  const padingHeightBottom = height / 6;
  const matches = useMediaQuery("(min-width: 832px)");

  const goForm = () => {
    setState(true);
  };
  return (
    <div>
      {matches ? (
        <div
          dir="rtl"
          ref={containerRef}
          className={`${Style.Body} w-[75vw] mx-auto relative px-4 top-[1px] mt-9 mb-8 bg-white overflow-y-auto rounded-2xl py-4 text-black  scroll-ml-6`}
          style={{ height: `${height - 70}px` }}
        >
          {state === false ? (
            <div className="p-6">
              <h3>درخواست سفارش اولیه</h3>
              <div>
                <p>
                  شما با تکمیل فرم پیش رو می‌توانید در تسریع برداشت سازمان شما
                  از سوی گروه چینش کمک کنید.
                </p>
                <div className={Style.Form}>
                  <p>
                    لطفا در نظر داشته باشید اطلاعاتی که در فرم زیر وارد می‌کنید
                    باعث ایجاد یک پیش فرض و تصویر کلی از سازمان شما در گروه چینش
                    خواهد شد، که به این ذهنیت ایجاد شده کمک در انجام مذاکرات آتی
                    خواهد داشت. لذا میزان ۱۵ دقیقه زمان را با حوصله صرف تکمیل
                    فرم نمایید.
                  </p>
                </div>
                <div className={Style.btn}>
                  <button
                    className="text-[#00c846]  focus:shadow-outline w-[15rem] focus:outline-none  border-2 border-[#00c846]  font-bold py-2 px-4 rounded-full"
                    onClick={goForm}
                  >
                    فرم درخواست اولیه سفارش
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <Form />
          )}
        </div>
      ) : (
        <div>
          <MobileOrder />
        </div>
      )}
    </div>
  );
};

export default OrderRequest;
