import React from "react";
import Style from "../../Style.module.scss";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
const schema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().required().email(),
  password: Yup.string().required().min(7),
});

const Form = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },

    // Pass the Yup schema to validate the form
    validationSchema: schema,

    // Handle form submission
    onSubmit: async ({ name, email, password }) => {
      // Make a request to your backend to store the data
    },
  });
  const { errors, touched, values, handleChange, handleSubmit } = formik;

  return (
    <div className="p-6">
      <h3 > فرم درخواست اولیه</h3>
      <div >
        <p>
          این فرم بر اساس پایه‌های کلی یک سفارش که در آینده از سوی گروه چینش به
          عنوان یک یا چند گروه خدمات معرفی می‌گردد، کاربرد دارد.
        </p>
        <p>
          لذا در صورت امکان این فرم را با دقت تکمیل نموده و برای ما ارسال
          نمایید.
        </p>
        <p>زمان کلی تکمیل این فرم ۱۲ الی ۱۵ دقیقه می‌باشد.</p>
        <div>
          <form onSubmit={handleSubmit} method="POST">
            <div className="flex flex-row justify-between mt-4 items-center mb-5 ">
              <div >
                <input
                  type="text"
                  name="Name"
                  placeholder="نام مجموعه"
                  // value={values.name}
                  // onChange={handleChange}
                  id="Name"
                  className="appearance-none block w-full  text-gray-700 border border-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                />
                {errors.name && touched.name && <span>{errors.name}</span>}

                <input
                  type="text"
                  name="Family"
                  placeholder="نام برند"
                  // value={values.name}
                  // onChange={handleChange}
                  id="Family"
                  className="appearance-none block w-full  text-gray-700 border border-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                />
                <div>
                  <p id="education">نوع سازمان</p>
                  <div
                    role="group"
                    aria-labelledby="education"
                    className="flex flex-row justify-start items-baseline flex-wrap "
                  >
                    <label for="diploma" className="pr-1 flex">
                      <input
                        type="radio"
                        value="دیپلم"
                        id="diploma"
                        name="education"
                      />
                      دولتی
                    </label>
                    <label for="associate-degree" className="pr-1 flex">
                      <input
                        type="radio"
                        value="فوق دیپلم"
                        id="associate-degree"
                        name="education"
                      />
                      خصوصی
                    </label>
                    <label for="bachelor" className="pr-1 flex">
                      <input
                        type="radio"
                        value="کارشناسی"
                        id="bachelor"
                        name="education"
                      />
                      نیمه دولتی
                    </label>
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div className="mt-5">
                    <p>چه کسانی مشتریان شما هستند؟</p>
                    <div
                      className="flex"
                      role="group"
                      aria-labelledby="NeedJog"
                    >
                      <div className="flex flex-col">
                        <label for="NeedJog1">
                          <input
                            type="checkbox"
                            value="افراد عمومی"
                            id="NeedJog1"
                            name="NeedJog"
                            className="ml-1"
                          />
                          افراد عمومی
                        </label>
                        <label for="NeedJog2">
                          <input
                            type="checkbox"
                            value="شرکت‌ها"
                            id="NeedJog2"
                            name="NeedJog"
                            className="ml-1"
                          />
                          شرکت‌ها
                        </label>
                        <label for="NeedJog3">
                          <input
                            type="checkbox"
                            value=" موسسات"
                            id="NeedJog3"
                            name="NeedJog"
                            className="ml-1"
                          />
                          موسسات
                        </label>
                        <label for="NeedJog4">
                          <input
                            type="checkbox"
                            value=" سازمان های دولتی"
                            id="NeedJog4"
                            name="NeedJog"
                            className="ml-1"
                          />
                          سازمان های دولتی
                        </label>

                        <label for="NeedJog5">
                          <input
                            type="checkbox"
                            value=" واسطه ها"
                            id="NeedJog5"
                            name="NeedJog"
                            className="ml-1"
                          />
                          واسطه‌ها
                        </label>
                        <label for="NeedJog6">
                          <input
                            type="checkbox"
                            value=" سایر"
                            id="NeedJog6"
                            name="NeedJog"
                            className="ml-1"
                          />
                          سایر
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <p>نوع سرمایه گذاری سازمان را مشخص کنید</p>
                    <div
                      className="flex"
                      role="group"
                      aria-labelledby="investment"
                    >
                      <div className="flex flex-col">
                        <label for="investment1">
                          <input
                            type="checkbox"
                            value="شخصی"
                            id="investment1"
                            name="NeedJog"
                            className="ml-1"
                          />
                          شخصی
                        </label>
                        <label for="investment2">
                          <input
                            type="checkbox"
                            value="خانوادگی"
                            id="investment2"
                            name="NeedJog"
                            className="ml-1"
                          />
                          خانوادگی
                        </label>
                        <label for="investment3">
                          <input
                            type="checkbox"
                            value=" دولتی"
                            id="investment3"
                            name="NeedJog"
                            className="ml-1"
                          />
                          دولتی
                        </label>
                        <label for="NeedJog4">
                          <input
                            type="checkbox"
                            value=" عمومی"
                            id="investment4"
                            name="NeedJog"
                            className="ml-1"
                          />
                          عمومی
                        </label>
                        <label for="NeedJog6">
                          <input
                            type="checkbox"
                            value=" سایر"
                            id="investment6"
                            name="NeedJog"
                            className="ml-1"
                          />
                          سایر
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p id="education">موقیعت جغرافیایی مرکز شما</p>
                    <div
                      role="group"
                      aria-labelledby="education"
                      className="flex flex-col  items-start flex-wrap "
                    >
                      <label for="inside-Iran" className="pr-1 flex">
                        <input
                          type="radio"
                          value="دیپلم"
                          id="inside-Iran"
                          name="education"
                        />
                        داخل ایران
                      </label>
                      <label for="outside-Iran" className="pr-1 flex">
                        <input
                          type="radio"
                          value="فوق دیپلم"
                          id="outside-Iran"
                          name="education"
                        />
                        خارج ایران
                      </label>
                    </div>
                  </div>
                  <div>
                    <p id="education">نوع نفرات سازمان</p>
                    <div
                      className="flex"
                      role="group"
                      aria-labelledby="NeedJog"
                    >
                      <div className="flex flex-col">
                        <label for="Queue">
                          <input
                            type="checkbox"
                            // value="افراد عمومی"
                            id="Queue"
                            name="NeedJog"
                            className="ml-1"
                          />
                          صف
                        </label>
                        <label for="headquarters">
                          <input
                            type="checkbox"
                            // value="شرکت‌ها"
                            id="headquarters"
                            name="NeedJog"
                            className="ml-1"
                          />
                          ستاد
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  name="Management"
                  placeholder="مدیرعامل"
                  // value={values.name}
                  // onChange={handleChange}
                  id="Management"
                  className="appearance-none block w-full  text-gray-700 border border-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                />
                <label for="Name">سال تاسیس</label>
                <input
                  type="date"
                  name="Management"
                  placeholder="سال تاسیس"
                  // value={values.name}
                  // onChange={handleChange}
                  id="Management"
                  className="appearance-none block w-full  text-gray-700 border border-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                />

                <div>
                  <p id="education">آیا سازمان شما وبسایت دارد؟</p>
                  <div
                    role="group"
                    aria-labelledby="education"
                    className="flex flex-row justify-start items-baseline flex-wrap "
                  >
                    <label for="diploma" className="pr-1 flex">
                      <input
                        type="radio"
                        value="دیپلم"
                        id="diploma"
                        name="education"
                      />
                      بله
                    </label>
                    <label for="bachelor" className="pr-1 flex">
                      <input
                        type="radio"
                        value="کارشناسی"
                        id="bachelor"
                        name="education"
                      />
                      خیر
                    </label>
                  </div>
                </div>

                <input
                  type="tel"
                  name="telphone"
                  placeholder="تلفن"
                  // value={values.name}
                  // onChange={handleChange}
                  id="telphone"
                  className="appearance-none block w-full  text-gray-700 border border-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                />
                <input
                  type="email"
                  name="email"
                  placeholder="ایمیل"
                  // value={values.name}
                  // onChange={handleChange}
                  id="email"
                  className="appearance-none block w-full  text-gray-700 border border-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                />
                <div className="flex flex-row justify-between">
                  <div>
                    <p id="product-type">نوع محصولات شما</p>
                    <div
                      className="flex"
                      role="group"
                      aria-labelledby="product-type"
                    >
                      <div className="flex flex-col">
                        <label for="product-type1">
                          <input
                            type="checkbox"
                            value="افراد عمومی"
                            id="product-type1"
                            name="product-type"
                            className="ml-1"
                          />
                          کالا
                        </label>
                        <label for="product-type2">
                          <input
                            type="checkbox"
                            value="شرکت‌ها"
                            id="product-type2"
                            name="product-type"
                            className="ml-1"
                          />
                          خدمات
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p id="education">
                      آیا مجموعه شما داری نمایندگی یا شعبه می باشد؟
                    </p>
                    <div
                      className="flex"
                      role="group"
                      aria-labelledby="Representation"
                    >
                      <div className="flex flex-col">
                        <label for="Yes">
                          <input
                            type="radio"
                            value="افراد عمومی"
                            id="Yes"
                            name="Representation"
                            className="ml-1"
                          />
                          بله
                        </label>
                        <label for="No">
                          <input
                            type="radio"
                            value="شرکت‌ها"
                            id="No"
                            name="Representation"
                            className="ml-1"
                          />
                          خیر
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>
                      آیا مجموعه شما داری گواهینامه ( های ) سیستمی از مراجمع بین
                      الملی است؟
                    </p>
                    <div
                      className="flex"
                      role="group"
                      aria-labelledby="license"
                    >
                      <div className="flex flex-col">
                        <label id="yes-license">
                          <input
                            type="radio"
                            // value="افراد عمومی"
                            id="yes-license"
                            name="license"
                            className="ml-1"
                          />
                          بله
                        </label>
                        <label id="no-license">
                          <input
                            type="radio"
                            // value="شرکت‌ها"
                            id="no-license"
                            name="license"
                            className="ml-1"
                          />
                          خیر
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <label id="your-organization-chalenges">
                  در صورت تمایل مختصرا عنوان بفرمایید که هم اکنون محموعه شما با
                  چه ماسئل و چالشهایی روبروست؟
                </label>
                <textarea
                  className="resize-y rounded-md p-8 ml-16 mt-1 w-full py-3 px-4 mb-3 text-gray-700"
                  id="your-organization-chalenges"
                />
                <label id="your-rivals">
                  رقبای خود را معرفی کرده و سابقه حصو آنها و همچنین سهم تخمینی بازار آنها را در مقایسه با مجموعه خود مشخص نمایید
                </label>
                <textarea
                  className="resize-y rounded-md p-8 ml-16 mt-1 w-full py-3 px-4 mb-3 text-gray-700"
                  id="your-rivals"
                />
                <div className="">
              
                    <p>نوع درخواست شما از گروه چینش چیست؟</p>
                    <div
                      className="flex "
                      role="group"
                      aria-labelledby="type-of-request"
                    >
                      <div className="flex flex-row">
                        <label for="type-of-request01">
                          <input
                            type="checkbox"
                            value="شخصی"
                            id="type-of-request01"
                            name="NeedJog"
                            className="ml-1"
                          />
                          طراحی و توسعه هویت سازمان
                        </label>
                        <label for="type-of-request02">
                          <input
                            type="checkbox"
                            value="خانوادگی"
                            id="type-of-request02"
                            name="NeedJog"
                            className="ml-1"
                          />
                          طراحی و توسعه هویت برند
                        </label>
                        <label for="type-of-request03">
                          <input
                            type="checkbox"
                            value=" دولتی"
                            id="type-of-request03"
                            name="NeedJog"
                            className="ml-1"
                          />
                          طراحی وب سازمان
                        </label>
                        <label for="type-of-request04">
                          <input
                            type="checkbox"
                            value=" عمومی"
                            id="type-of-request04"
                            name="NeedJog"
                            className="ml-1"
                          />
                          طراحی تصویر و گرافیک
                        </label>
                        <label for="type-of-request06">
                          <input
                            type="checkbox"
                            value=" سایر"
                            id="type-of-request06"
                            name="NeedJog"
                            className="ml-1"
                          />
                          طراحی و توسعه محصول
                        </label>
                        <label for="type-of-request07">
                          <input
                            type="checkbox"
                            value=" سایر"
                            id="type-of-request07"
                            name="NeedJog"
                            className="ml-1"
                          />
                          سایر
                        </label>
                      </div>
                    </div>
                 
                  
                  
                </div>
                <label id="explanation-of-request">
                  توضیحات تکمیلی درخواست شما از گروه دیاریس
                </label>
                <textarea
                  className="resize-y rounded-md p-8 ml-16 mt-1 w-full py-3 px-4 mb-3 text-gray-700"
                  id="explanation-of-request"
                />
                <div>
                    <p>
                      نحوه اشنایی با گروه چینش:
                    </p>
                    <div
                      className="flex"
                      role="group"
                      aria-labelledby="how-did-you-find-chinesh"
                    >
                      <div className="flex flex-row">
                        <label id="how-did-you-find-chinesh1">
                          <input
                            type="checkbox"
                            // value="افراد عمومی"
                            id="how-did-you-find-chinesh1"
                            name="license"
                            className="ml-1"
                          />
                          دوستان
                        </label>
                        <label id="how-did-you-find-chinesh2">
                          <input
                            type="checkbox"
                            // value="شرکت‌ها"
                            id="how-did-you-find-chinesh2"
                            name="license"
                            className="ml-1"
                          />
                          جستجو در اینترنت
                        </label>
                        <label id="how-did-you-find-chinesh3">
                          <input
                            type="checkbox"
                            // value="شرکت‌ها"
                            id="how-did-you-find-chinesh3"
                            name="license"
                            className="ml-1"
                          />
                          فضای مجازی
                        </label>
                      </div>
                    </div>
                  </div>
                  <input type="text " id="who-completed-form" 
                  placeholder="نام و نام خانوادگی تنظیم کننده"
                  className="appearance-none block w-full  text-gray-700 border border-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  
                  />
                
                <div className="flex  flex-row   ">
                  <button
                    className=" bg-green-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                    // onClick={handlePage}
                  >
                    ارسال فرم
                  </button>
                  <button
                    className=" bg-red-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 mr-2 rounded"
                    // onClick={handlePage}
                  >
                    فرم جدید
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
