import React, { useEffect, useRef, useState } from "react";
import Style from "./Style.module.scss";
import Form from "./components/form/Form";
import { useDispatch, useSelector } from "react-redux";
// import { scrollPage } from "../GlobalRedux/Features/scroll/scrollSlice";
// import { visiblePage } from "../GlobalRedux/Features/visible/visibleSlice";
import useScreenHeight from "../../hook/useScreenHeight";
import useWindowSize from "hook/useWindowSize";
import useMediaQuery from "hook/useMediaQuery";
import MobileCaree from "./Mobile/MobileCaree";

const CareerOpportunities = () => {
  const [state, setState] = useState(false);
  const matches = useMediaQuery("(min-width: 832px)");
  const scrollBody = useSelector((state) => state.scroll.value);
  const dispatch = useDispatch();
  const containerRef = useRef(null); // ایجاد ref برای کانتینر
  const screenHeight = useScreenHeight();
  const { width, height } = useWindowSize();
  //این هم برای تنظیم اندازه ای که کانیتر اطلاعات ما می گیرد تنظیم می شود
  const padingHeightBottom = height / 10;
  const handleBigContainer = () => {
    if (state === false) {
      setState(true);
    } else {
      setState(false);
    }
  };
  return (
    <div dir="rtl">
      {matches ? (
        <div
          ref={containerRef}
          className={`${Style.Body} w-[75vw] mx-auto relative  top-[1px] mt-9 mb-8 bg-white overflow-y-auto rounded-2xl p-6 text-black  scroll-ml-6`}
          style={{ height: `${height - 70}px` }}
        >
          <p>همکاری با دیاریس</p>

          <div>
            <p>
              همکاری واژه‌ای پایدار در کشورهای در حال توسعه و توسعه یافته است.
            </p>
            <p>
              این نگاه زمانی به عنوان محرک جامعه نمود پیدا می‌کند که تمام اعضای
              آن جامعه با ایمان به رشد و توسعه به بیداری جامعه فکر کنند.
            </p>
            <div className={Style.Form}>
              <p>
                برای چینش سبب خرسندی خواهد بود که دایره‌ی کارشناسانی که با این
                مجموعه همراه می شوند هر روز گسترش دهد. افرادی که با دیدگاهی
                درخشش یافته از باوری عمیق به ارزش های انسانی و قدرت گرفته از
                اعتماد به توانایی خویش، قواعد تجلی مهارت و تخصص خویش را در قالب
                تیم های تخصصی و محدوده های زمانی به خوبی می شناسند. در صورتی که
                چنین باور درخشان و مهارتی دارید، حتما با وارد نمودن اطلاعات
                خواسته شده، به ما بپیوندید.
              </p>
              <Form handleBigContainer={handleBigContainer} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <MobileCaree />
        </div>
      )}
    </div>
  );
};

export default CareerOpportunities;
