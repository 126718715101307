import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  value: 0 ,
}

export const visibleSlice = createSlice({
  name: 'scrollPlay',
  initialState,
  reducers: {
    visiblePage:(state,action)=>{
      state.value = action.payload
    },
   
  },
})

export const {visiblePage} = visibleSlice.actions
export default visibleSlice.reducer