import React from 'react'

const NoMatch = () => {
  return (
    <div className='w-[100%] ml-[50%]'>
        <h1 className='text-white text-lg'>
            Not Found 404
        </h1>
    </div>
  )
}

export default NoMatch