import React from "react";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
const schema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().required().email(),
  password: Yup.string().required().min(7),
});
const PartTwo = ({ backPage }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },

    // Pass the Yup schema to validate the form
    validationSchema: schema,

    // Handle form submission
    onSubmit: async ({ name, email, password }) => {
      // Make a request to your backend to store the data
    },
  });
  const { errors, touched, values, handleChange, handleSubmit } = formik;
  const handleBack = () => {
    backPage();
  };
  return (
    <div>
      <form onSubmit={handleSubmit} method="POST">
        <div className="flex flex-col  justify-end items-start flex-wrap mt-4">
          <div className="border border-black rounded-lg h-16 w-full pr-1 mt-4">
            <p>به کدام نوع از شرایط کاری زیر تمایل دارید؟</p>
            <select className="w-full !outline-none">
                <option value="0"> بلند مدت، تمام وقت</option>
                <option value="1">  کوتاه مدت، تمام وقت</option>
                <option value="2"> بلند مدت، پاره وقت</option>
                <option value="3">کوتاه مدت، پاره وقت</option>
                <option value="4"> پروژه‌ای </option>
              </select>
          </div>
          <div className="mt-5">
            <p>پست‌هایی که مایلید در آن کار بکنید</p>
            <div className="flex" role="group" aria-labelledby="NeedJog">
              <div className="flex flex-col">
                <label for="NeedJog1">
                  <input
                    type="checkbox"
                    value="وب، برنامه نویسی  و نرم افزار"
                    id="NeedJog1"
                    name="NeedJog"
                    className="ml-1"
                  />
                  وب، برنامه نویسی و نرم افزار
                </label>
                <label for="NeedJog2">
                  <input
                    type="checkbox"
                    value="فروش و بازاریابی"
                    id="NeedJog2"
                    name="NeedJog"
                    className="ml-1"
                  />
                  فروش و بازاریابی
                </label>
                <label for="NeedJog3">
                  <input
                    type="checkbox"
                    value=" تولید و مدیریت محتوا"
                    id="NeedJog3"
                    name="NeedJog"
                    className="ml-1"
                  />
                  تولید و مدیریت محتوا{" "}
                </label>
                <label for="NeedJog4">
                  <input
                    type="checkbox"
                    value=" طراحی گرافیک"
                    id="NeedJog4"
                    name="NeedJog"
                    className="ml-1"
                  />
                  طراحی گرافیک{" "}
                </label>

                <label for="NeedJog5">
                  <input
                    type="checkbox"
                    value=" مسئول دفتر، اجرائی و اداری"
                    id="NeedJog5"
                    name="NeedJog"
                    className="ml-1"
                  />
                  مسئول دفتر، اجرائی و اداری{" "}
                </label>
              </div>
              <div className="flex flex-col mr-10">
                <label for="NeedJog6">
                  <input
                    type="checkbox"
                    value=" مالی و حسابداری"
                    id="NeedJog6"
                    name="NeedJog"
                    className="ml-1"
                  />
                  مالی و حسابداری{" "}
                </label>
                <label for="NeedJog7">
                  <input
                    type="checkbox"
                    value=" IT / DevOps / Server"
                    id="NeedJog7"
                    name="NeedJog"
                    className="ml-1"
                  />
                  IT / DevOps / Server{" "}
                </label>
                <label for="NeedJog8">
                  <input
                    type="checkbox"
                    value=" پشتیبانی و امور مشتریان"
                    id="NeedJog8"
                    name="NeedJog"
                    className="ml-1"
                  />
                  پشتیبانی و امور مشتریان
                </label>
                <label for="NeedJog9">
                  <input
                    type="checkbox"
                    value="آموزش"
                    id="NeedJog9"
                    name="NeedJog"
                    className="ml-1"
                  />
                  آموزش
                </label>

                <label for="NeedJog10">
                  <input
                    type="checkbox"
                    value=" منابع انسانی و کارگزینی"
                    id="NeedJog10"
                    name="NeedJog"
                    className="ml-1"
                  />
                  منابع انسانی و کارگزینی
                </label>
              </div>
              <div className="flex flex-col mr-10">
                <label for="NeedJog11">
                  <input
                    type="checkbox"
                    value=" مهندسی صنایع و مدیریت صنعتی"
                    id="NeedJog11"
                    name="NeedJog"
                    className="ml-1"
                  />
                  مهندسی صنایع و مدیریت صنعتی
                </label>
                <label for="NeedJog12">
                  <input
                    type="checkbox"
                    value="ترجمه"
                    id="NeedJog12"
                    name="NeedJog"
                    className="ml-1"
                  />
                  ترجمه
                </label>
                <label for="NeedJog13">
                  <input
                    type="checkbox"
                    value=" تحقیق و توسعه"
                    id="NeedJog13"
                    name="NeedJog"
                    className="ml-1"
                  />
                  تحقیق و توسعه
                </label>
                <label for="NeedJog14">
                  <input
                    type="checkbox"
                    value="  تحقیق بازار و تحلیل اقتصادی"
                    id="NeedJog14"
                    name="NeedJog"
                    className="ml-1"
                  />
                  تحقیق بازار و تحلیل اقتصادی
                </label>

                <label for="NeedJog15">
                  <input
                    type="checkbox"
                    value=" روابط عمومی"
                    id="NeedJog15"
                    name="NeedJog"
                    className="ml-1"
                  />
                  روابط عمومی{" "}
                </label>
              </div>
              <div className="flex flex-col mr-10">
                <label for="NeedJog16">
                  <input
                    type="checkbox"
                    value=" حمل و نقل"
                    id="NeedJog16"
                    name="NeedJog"
                    className="ml-1"
                  />
                  حمل و نقل{" "}
                </label>
                <label for="NeedJog17">
                  <input
                    type="checkbox"
                    value="پژوهش و پژوهشگری"
                    id="NeedJog17"
                    name="NeedJog"
                    className="ml-1"
                  />
                  پژوهش و پژوهشگری
                </label>

                <label for="NeedJog19">
                  <input
                    type="checkbox"
                    value="CEO"
                    id="NeedJog19"
                    name="NeedJog"
                    className="ml-1"
                  />
                  CEO
                </label>

                <label for="NeedJog20">
                  <input
                    type="checkbox"
                    value="خدمات اداری"
                    id="NeedJog20"
                    name="NeedJog"
                    className="ml-1"
                  />
                  خدمات اداری
                </label>
                <label for="NeedJog18">
                  <input
                    type="checkbox"
                    value=" موارد دیگر"
                    id="NeedJog18"
                    name="NeedJog"
                    className="ml-1"
                  />
                  موارد دیگر
                </label>
              </div>
            </div>
          </div>
          {/* <div className="mt-3 flex flex-col">
            <label for="cv">
              رزومه خود را ارسال کنید
              </label>
            <input type="file" name="cv" id="cv" value="انتخاب کنید" accept=".pdf" />
        
          </div> */}
        </div>
        <div className="flex  flex-row justify-start p-1 mt-5 mb-32">
          <button className=" bg-green-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded">
            ارسال نهایی فرم
          </button>
          <button
            className="mr-3 bg-yellow-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            onClick={handleBack}
          >
            برگشت به اول فرم
          </button>
          <button className="mr-3 bg-red-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded">
            فرم جدید
          </button>
        </div>
      </form>
    </div>
  );
};

export default PartTwo;
