import React, { useState } from "react";
import LogoChinesh from "assets/SVG/Horizental.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Style from "./Style.module.scss";
import { useChangeColor } from "zstand/BlackOrGreen";
import { useCountStore } from "zstand/animatin";
import "./MenuApple/style.css"

const MobileMenu = ({ handleLogoHeader, handleNotShowLogoHeader }) => {
  const { goBlack, goGreen } = useChangeColor((state) => state.actions);
  const { noAnime , goAnime , stopAnime } = useCountStore((state) => state.actions);
  const [state, setState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleClick = () => {
    setState((prev) => {
      if (!prev) {
        handleLogoHeader();
        goBlack();
      } else {
        handleNotShowLogoHeader();
        goGreen();
      }
      return !prev;
    });
  };

  return (
    <div className="">
      <button onClick={handleClick} className="h-4 w-12 flex flex-col justify-between">
        <div className="w-10 bg-white h-[2px] rounded-full"></div>
        <div className="w-10 bg-white h-[2px] rounded-full"></div>
      </button>
      {/* <div className={`menu-container `} onClick={toggleMenu}>
        <div className="line line-1">
          <div className="line-inner line-inner-1"></div>
        </div>
        <div className="line line-2">
          <div className="line-inner line-inner-2"></div>
        </div>
      </div> */}
      {state && (
        <div>
          <div className="w-[100%] text-white py-4  text-center absolute  top-[5rem] right-0 -left-48 font-semibold text-[25px]">
            <span>
              {" "}
              {/* <marquee direction = "right" scrollamount="30"  >&nbsp; Development & Creatin of Identity Development & Creatin of Identity Development & Creatin of Identity Development & Creatin of Identity Development & Creatin of Identity </marquee> */}
              <span className={Style.track}>
                {" "}
                <i>
                  Development & Creatin of Identity Development & Creatin of
                  Identity Development & Creatin of Identity Development &
                  Creatin of Identity Development & Creatin of Identity
                  Development & Creatin of Identity Development & Creatin of
                  Identity Development & Creatin of Identity Development &
                  Creatin of Identity Development & Creatin of Identity
                  Development & Creatin of Identity Development & Creatin of
                  Identity Development & Creatin of Identity Development &
                  Creatin of Identity Development & Creatin of Identity
                  Development & Creatin of Identity Development & Creatin of
                  Identity Development & Creatin of Identity
                </i>{" "}
              </span>
            </span>
          </div>

          <div className={`w-[101vw] h-[100vh] absolute bg-[#00c846] top-[9rem] right-0 left-0 ${Style.AnimeShow}`}>
            <ul className="flex flex-col justify-start items-start mr-8">
              <li className="py-2  ">
                <span
                  onClick={() => {
                    navigate("/");
                    handleClick();
                    // stopAnime()
                    if(location.pathname !== "/"){
                      stopAnime();
                    } 
                  }}
                  className={`${Style.MenuFrist1} font-bold text-[36px]`}
                >
                  بینـــــــش
                </span>
              </li>
              <li className="  w-[90%]  py-2 ">
                <span
                  onClick={() => {
                    navigate("/diyaris");
                    handleClick();
                    noAnime();
                  }}
                  className={`${Style.MenuFrist2} font-bold text-[36px]`}
                >
                  دیاریــــس
                </span>
              </li>
              <li className="  w-[90%]  py-2 ">
                <span
                  onClick={() => {
                    navigate("/services");
                    handleClick();
                    noAnime();

                  }}
                  className={`${Style.MenuFrist3} font-bold text-[36px]`}
                >
                  خدمـــــات
                </span>
              </li>
              <li className="  w-[90%]  py-2 ">
                <span
                  onClick={() => {
                    navigate("/Customer");
                    handleClick();
                    noAnime();
                  }}
                  className={`${Style.MenuFrist4} font-bold text-[36px]`}
                >
                  مشـــتریان
                </span>
              </li>
              <li className="  w-[90%] py-4 ">
                <a href="https://chinesh.com/" target="_blank">
                  {" "}
                  <img
                    src={LogoChinesh}
                    alt="LogoChinesh"
                    width={130}
                    className={`${Style.LogoChinesh}`}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
