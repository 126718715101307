import React from "react";

const FormAboutUs = () => {
  return (
    <div>
      <form action="" className="">
        <div className="flex flex-row flex-wrap justify-between mt-4">
          <div className="flex  flex-col justify-around p-1 w-full">
            <input
              type="text"
              placeholder="نام و نام خلانودگی*"
              className="appearance-none block w-full border-black  text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
            />
            <input
              type="email"
              placeholder="پست الکترونیکی*"
              className="appearance-none block w-full border-black text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            />
            <input
              type="tel"
              placeholder="تلفن همراه"
              className="appearance-none block w-full border-black text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            />
          </div>
          <div className="flex  flex-col justify-around p-1 w-full">
            <input
              type="text"
              placeholder="نام سازمان"
              className="appearance-none block w-full border-black text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            />
            <input
              type="text"
              placeholder="فعالیت سازمان"
              className="appearance-none block w-full  border-black text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            />
            <input
              type="text"
              placeholder="موضوع ارتباط"
              className="appearance-none block w-full border-black text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            />
          </div>
          <textarea
            className="resize-y border-black border rounded-md p-8 mr-4 ml-16 mt-1 w-full py-3 px-4 mb-3 text-gray-700"
            placeholder="پیام"
          />
        </div>
        <div className="flex p-1 ">
          <textarea
            className="resize-y rounded-md p-8 border border-black ml-64 mt-1 w-full py-3 px-4 mb-3 text-gray-700"
            placeholder="captcha"
          />
        </div>
        <div className="flex p-1 ">
          <button
            className="shadow bg-green-500 hover:bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="submit"
          >
            ارسال
          </button>
          <button
            className="shadow mr-5 bg-red-500 hover:bg-red-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="button"
          >
            بیخیال
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormAboutUs;
