import React, { useEffect, useState } from "react";
import Style from "./Style.module.scss";
import LogoDyaris from "./components/logo home/LogoDyaris";
import MenuHome from "./components/Menue/MenuHome";
import HomeImage from "assets/JPG/Diyaris - Website (v2) - Picture (Desktop) - Color.jpg";
import HomeImageS from "assets/JPG/diyaris-picture-s.jpg";

import HeaderAnimation from "./components/Menue/HeaderAnimation";
import Slogan from "./components/BlackBox/Slogan";
import ContainerBlack from "./components/BlackBox/container/ContainerBlack";
import FooterAnime from "./components/Footer/FooterAnime";
import { useDispatch, useSelector } from "react-redux";
// import { moveByCounter } from "redux/Features/newAnime/newAnimeSlice";
import { useCountStore } from "zstand/animatin";
import useMediaQuery from "hook/useMediaQuery";
import Header from "Layout/Header/Header";
import Footer from "Layout/Footer/Footer";
const Home = () => {
  const count = useCountStore((state) => state.count);
  const { goAnime, backAnime } = useCountStore((state) => state.actions);
  console.log("count=",count)
  const [notShowHeader, showHeader] = useState(false);
  const matches = useMediaQuery("(min-width: 832px)");
  const matchesMobile = useMediaQuery("(min-width: 433px)");
  // useEffect(()=>{
  //   if(count === "noAnime"){
  //     showHeader(true);
  //   }
  // },[notShowHeader])
  const handelShowHeader = () => {
    if (notShowHeader === false) {
      showHeader(true);
      goAnime();
      console.log("count85", count);
    } else {
      showHeader(false);
    }
  };
  return (
    <div className={Style.Home}>
      {matchesMobile ? (
        <img
          src={HomeImage}
          alt="HomeImage"
          className={`${Style.ImageHome} `}
        />
      ) : (
        <div>
          <img
            src={HomeImageS}
            alt="HomeImage"
            className={`${Style.ImageHomeS} `}
          />
        </div>
      )}

      {(count === "noAnime" && notShowHeader === false) ||
      count === "startBackAnime" ? (
        <div className={count === "startBackAnime" ? Style.Delay : null}>
          <MenuHome showHeader={handelShowHeader} />
        </div>
      ) : null}
      {notShowHeader === true || count === "stopAnime" ? (
        <div>
          <div className="fixed z-20"><Slogan showHeader={handelShowHeader} activeProp={notShowHeader} /></div> 
          {matches ? (
            <HeaderAnimation
              showHeader={handelShowHeader}
              activeProp={notShowHeader}
            />
          ) : (
            <Header  />
          )}
          <ContainerBlack
            showHeader={handelShowHeader}
            activeProp={notShowHeader}
          />
          {matches ? <FooterAnime /> : <Footer />}
        </div>
      ) : null}

      {/* </div> */}
    </div>
  );
};

export default Home;
