import React, { useState } from "react";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
const schema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().required().email(),
  password: Yup.string().required().min(7),
});
const InputSms = ({handleSms}) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },

    // Pass the Yup schema to validate the form
    validationSchema: schema,

    // Handle form submission
    onSubmit: async ({ name, email, password }) => {
      // Make a request to your backend to store the data
    },
  });
  const { errors, touched, values, handleChange, handleSubmit } = formik;
  // const handlePage = () => {
  //   nextPage();
  // };
  const sendSms = () =>{
     handleSms();
  }
  return (
    <div>
      <form onSubmit={handleSubmit} method="POST">
        <div className="flex flex-col justify-between mt-4 items-start ">
          <div className="flex  flex-row justify-around p-1 w-full">
            <div>
              <label htmlFor="Name">نام</label>
              <input
                type="text"
                name="Name"
                // value={values.name}
                // onChange={handleChange}
                id="Name"
                className="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              />
              {errors.name && touched.name && <span>{errors.name}</span>}
            </div>
            <div>
              <label htmlFor="Family">نام‌خانوادگی</label>
              <input
                type="text"
                name="Family"
                // value={values.name}
                // onChange={handleChange}
                id="Family"
                className="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              />
            </div>
            <div>
              <label htmlFor="Address">شماره تلفن</label>
              <input
                type="tel"
                name="tel"
                // value={values.name}
                // onChange={handleChange}
                id="tel"
                className="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              />
            </div>
          </div>

          <div className="flex  flex-row justify-around p-1 ">
            <div className="flex flex-row">
              <p id="education">جنسیت:</p>
              <input type="radio" id="Man" name="list-radio" />
              <label htmlFor="Man">مرد</label>
              <input type="radio" id="Woman" name="list-radio" />
              <label htmlFor="Woman">زن</label>
            </div>
          </div>
        </div>
        <div className="flex  flex-col justify-around p-1 ">
          <button
            className=" bg-blue-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            onClick={sendSms}
          >
            صفحه بعد
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputSms;
