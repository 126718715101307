import React, { useEffect, useState, useRef } from "react";
import Styles from "./Style.module.scss";
import Text from "assets/Text/diyaris/db.json";
import Up from "assets/SVG/Up.svg";
import Down from "assets/SVG/Down.svg";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import { useDispatch, useSelector } from "react-redux";
import useScreenHeight from "../../hook/useScreenHeight";
import Footer from "Layout/Footer/Footer";
import useWindowSize from "hook/useWindowSize";
import useMediaQuery from "hook/useMediaQuery";
import MobileDiyaris from "./Mobile/MobileDiyaris";

const Diyaris = () => {
  const [active, setActive] = useState(null);
  const menu = Text.map((item) => item.title);
  const activeBody = Text[active]?.body;
  const pagination = Text.map((item) => item.pagination);
  const firstSlideRef = useRef();
  const secondSlideRef = useRef();
  const menuFirst = useRef();
  const scrollBody = useSelector((state) => state.scroll.value);
  const dispatch = useDispatch();
  const containerRef = useRef(null); // ایجاد ref برای کانتینر
  const screenHeight = useScreenHeight();
  //از این جا برای ثابت کردن اندازه استفاده می شود
  const { width, height } = useWindowSize();
  const matches = useMediaQuery("(min-width: 832px)");
  useEffect(() => {
    if (active === null) {
      setActive(0);
    }
  }, []);

  useEffect(() => {
    if (firstSlideRef.current && secondSlideRef.current) {
      firstSlideRef.current.sync(secondSlideRef.current.splide);
    }
  }, [firstSlideRef, secondSlideRef]);

  const handleSplide = (splide) => {
    setActive(splide.index);
  };

  return (
    <div dir="rtl">
      {matches ? (
        <div>
          {/* <div className={`${Styles.ContainerBlack}`}></div> */}
          <div className={`${Styles.Menu} w-[75vw] h-16 mt-4 mx-auto bg-white  rounded-full`}>
            <Splide
              ref={firstSlideRef}
              className="text-black relative -top-10"
              aria-label="Menu"
              options={{
                rewind: true,
                arrows: false,
                gap: "2px",
                type: "loop",
                direction: "rtl",
                perPage: 3,
                perMove: 1,
                focus: "center",
                pagination: false,
                wheel: true,
                omitEnd: true,
                speed: 1000,
                isNavigation: true,
                pauseOnFocus: true,
                breakpoints: {
                  1213: {
                    perPage: 1,
                  },
                },
              }}
              onMoved={handleSplide}
            >
              {menu.map((title, index) => (
                <SplideSlide
                  ref={menuFirst}
                  key={index}
                  className={`rounded-full text-center max-w-fit ${
                    index === active
                      ? "border-[3px]  text-[#00c846] mx-2 h-12  border-[#00c846] font-bold text-[21px] px-2 py-1 "
                      : "text-[18px] px-4 py-2   hover:text-black text-gray-400 rounded-full"
                  }`}
                >
                  {title}
                </SplideSlide>
              ))}
            </Splide>
          </div>
          <div
            className={`${Styles.Body}  mx-auto  p-6  `}
            id="myContainer"
            ref={containerRef}
            style={{ height: `${height-115}px ` }}
          >
            <div
              className={Styles.Pragragh}
              id="myContainer"
              ref={containerRef}
            >
              {activeBody?.map((item, index) => (
                <div key={index} className={Styles.PragraghItem}>
                  {item.head && <h1 className={Styles.Head}>{item.head}</h1>}
                  <p className={Styles.SubPragraph}>{item.pragragh}</p>
                  {item.list && (
                    <ul className={Styles.List}>
                      {item.list.map((listItem, idx) => (
                        <li key={idx}>{Object.values(listItem)[0]}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>
          <Footer>
            <div className={`${Styles.Pagination} rounded-full`}>
              <Splide
                ref={secondSlideRef}
                aria-label="Pagination"
                onMoved={handleSplide}
                hasTrack={false}
                options={{
                  rewind: true,
                  arrows: false,
                  type: "loop",
                  direction: "ttb",
                  perPage: 3,
                  perMove: 1,
                  height: "8rem",
                  width: "20rem",
                  focus: "center",
                  pagination: false,
                  speed: 1000,
                  wheel: true,
                  isNavigation: true,
                  breakpoints: {
                    600: {
                      perPage: 3,
                      height: "10rem",
                    },
                  },
                }}
              >
                <SplideTrack>
                  {pagination.map((item, index) => (
                    <SplideSlide
                      key={index}
                      className={`${
                        index === active
                          ? ` border-[3px] w-14 font-bold text-[21px] text-[#00c846] mx-2 px-1 text-center py-1  border-[#00c846]  rounded-full`
                          : `text-[18px]   py-2  text-gray-400 rounded-full`
                      }`}
                    >
                      {item}
                    </SplideSlide>
                  ))}
                </SplideTrack>
              </Splide>
            </div>
          </Footer>
        </div>
      ) : (
        <div>
          <MobileDiyaris />
        </div>
      )}
    </div>
  );
};

export default Diyaris;
