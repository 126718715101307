import React, { useEffect, useRef, useState } from "react";
import Style from "../Style.module.scss";
import StyleTwo from "./Style.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import useWindowSize from "hook/useWindowSize";
import { NewData } from "assets/Text/customer/Data";
import Diyaris from "assets/SVG/Monogram.svg";
import AABIII from "assets/SVG/AABIII.svg";
import Sign from "assets/SVG/Sign.svg";
import Circle from "assets/SVG/diyaris-cv-circle.svg";
import Squre from "assets/SVG/diyaris-aabiii-cv-square.svg";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import Green from "assets/Customers/Logo/JPG/green.jpg";
import useMediaQuery from "hook/useMediaQuery";
import useCoustomerFinder from "zstand/coustomerFinder";
import { useChangeColor } from "zstand/BlackOrGreen";
import Footer from "Layout/Footer/Footer";
const MobileDetails = () => {
  const params = useParams();
  const matches = useMediaQuery("(min-width: 435px)");
  const { value, findCustomer } = useCoustomerFinder();
  const [selectedLogo, setSelectedLogo] = useState(null);
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const color = useChangeColor((state)=>state.color);
  const foundCustomer = NewData?.find(
    (item) => item.Title === decodeURIComponent(params.id)
  );
  const [active, setActive] = useState(foundCustomer ? foundCustomer.id : 0);
  useEffect(() => {
    const foundCustomer = NewData.find(
      (item) => item.Title === decodeURIComponent(params.id)
    );
    if (foundCustomer) {
      // اگر لوگوی انتخاب شده با لوگوهای موجود همخوانی داشته باشد
      if (NewData.some((item) => item.Logo === foundCustomer.Logo)) {
        setSelectedLogo(foundCustomer.Logo);
      }
    }
  }, [params.id]);
  const firstSlideRef = useRef();
  const secondSlideRef = useRef();
  useEffect(() => {
    if (firstSlideRef.current && secondSlideRef.current) {
      firstSlideRef.current.sync(secondSlideRef.current.splide);
    }
  }, [firstSlideRef, secondSlideRef]);
  const handleSplide = (splide) => {
    setActive(splide.index);
    const findTitle = NewData?.find((item) => item.id === splide.index + 1);
    navigate(`/Customer/${findTitle.Title}`);
  };
  return (
    <div>
      <div className={`w-[100vw] ${color === "Black" ? "bg-black" : "bg-[#00c846]"} fixed top-20 h-16`}>
        <div className={`w-[100%] ${color === "Black" ? "opacity-0": "text-opacity-100" }   mx-auto relative  rounded-full`}>
          <Splide
            ref={secondSlideRef}
            aria-label="Pagination"
            onMoved={handleSplide}
            hasTrack={false}
            className="text-black relative -top-10"
            options={{
              rewind: true,
              arrows: false,
              type: "loop",
              direction: "rtl",
              rewindByDrag: true,
              speed: 1000,
              perPage: 1,
              perMove: 1,
              focus: "center",
              pagination: false,
              wheel: true,
              omitEnd: true,
              start: `${foundCustomer.id}`,
              isNavigation: true,
            }}
          >
            <SplideTrack>
              {NewData.map((item, index) => (
                <SplideSlide
                  key={index}
                  className={`rounded-full text-center max-w-fit ${
                    index === active
                      ? "border-[3px]   mx-2 h-12  border-[#00c846] font-bold text-[21px] px-2 py-1 "
                      : "text-[18px] px-4 py-2   hover:text-black text-black rounded-full"
                  }`}
                >
                  {item.Start}
                </SplideSlide>
              ))}
            </SplideTrack>
          </Splide>
        </div>
      </div>
      <div className={` fixed h-16 mt-[9rem] mx-auto bg-white  `}>
        <div className=" relative -top-[2.45rem]  mx-auto ">
         {matches ?  <Splide
            aria-label="My Favorite Images"
            ref={firstSlideRef}
            onMoved={handleSplide}
            className="w-[100vw]"
            options={{
              rewind: true,
              arrows: false,
              gap: "0rem",
              padding:{left:-5 , right:5},
              type: "loop",
              direction: "rtl",
              speed: 1000,
              perPage: 8,
              perMove: 1,
              height: "3rem",
              focus: "center",
              pagination: false,
              wheel: true,
              omitEnd: true,
              isNavigation: true,
              start: `${value}`,
              breakpoints: {
                435: { perPage: 2 , gap:"1rem"},
              },
            }}
          >
            {NewData.map((item, index) => (
              <SplideSlide key={index} className="">
                <div className={`${selectedLogo === index ? "" : ""}`}>
                  {index === active ? (
                    <div
                      style={{
                        // width: "60px",
                        height: "45px",
                        WebkitMaskImage: `url(${item.LogoSvg})`,
                        maskImage: `url(${item.LogoSvg})`,
                        maskRepeat: "no-repeat",
                        maskPosition: "center",
                        marginLeft: "auto",
                        marginTop: "auto",
                      }}
                    >
                      <img
                        src={Green}
                        alt="Logo"
                        className={`${Style.Mask} mx-auto w-full h-[70px]  `}
                      />
                    </div>
                  ) : (
                    <img
                      src={item.LogoSvg}
                      alt="Logo"
                      className={`mx-auto  h-[45px] opacity-30 `}
                    />
                  )}
                </div>
              </SplideSlide>
            ))}
          </Splide> :
            <Splide
            aria-label="My Favorite Images"
            ref={firstSlideRef}
            onMoved={handleSplide}
            className=""
            options={{
              rewind: true,
              arrows: false,
              gap: "0rem",
              padding:{left:-5 , right:5},
              type: "loop",
              direction: "rtl",
              speed: 1000,
              perPage: 5,
              perMove: 1,
              height: "3rem",
              focus: "center",
              pagination: false,
              wheel: true,
              omitEnd: true,
              isNavigation: true,
              start: `${foundCustomer.id}`,
              breakpoints: {
                435: { perPage: 2 , gap:"1rem"},
              },
            }}
          >
            {NewData.map((item, index) => (
              <SplideSlide key={index} className="">
                <div className={`${selectedLogo === index ? "" : ""}`}>
                  {index === active ? (
                    <div
                      style={{
                        // width: "60px",
                        height: "45px",
                        WebkitMaskImage: `url(${item.LogoSvg})`,
                        maskImage: `url(${item.LogoSvg})`,
                        maskRepeat: "no-repeat",
                        maskPosition: "center",
                        marginLeft: "auto",
                        marginTop: "auto",
                      }}
                    >
                      <img
                        src={Green}
                        alt="Logo"
                        className={`${Style.Mask} mx-auto w-full h-[70px]  `}
                      />
                    </div>
                  ) : (
                    <img
                      src={item.LogoSvg}
                      alt="Logo"
                      className={`mx-auto  h-[45px] opacity-30 `}
                    />
                  )}
                </div>
              </SplideSlide>
            ))}
          </Splide>
          } 
        
        </div>
      </div>
      <div
         className={`${Style.Body}  mx-auto relative top-[12rem] -z-20 text-[21px] text-white`}
         style={{ height: `${height - 370}px` }}
        dir="rtl"
      >
        {/* نام برند و نام شرکت و اطلاعات ابتدایی پروژه */}
        {foundCustomer && foundCustomer.Title ? (
          <div className={`${StyleTwo.Text} p-4`}>
            <div className={Style.Conainers}>
              <p className={`${StyleTwo.SmallContainer} rounded-md mt-3 mx-1`}>
                برند: {foundCustomer.Title}
              </p>
              <p className={`${StyleTwo.SmallContainer} rounded-md mt-3 mx-1`}>
                نام شرکت: {foundCustomer.Compony}
              </p>
              <p className={`${StyleTwo.SmallContainer} rounded-md mt-3 mx-1`}>
                تاریخ شروع فعالیت: {foundCustomer.Start}
              </p>
              <p className={`${StyleTwo.SmallContainer} rounded-md mt-3 mx-1`}>
                تاریخ پایان فعالیت: {foundCustomer.Finish}
              </p>
            </div>
            {/* معرفی شرکت  */}
            <div className={Style.explanation}>
              <h2 className="text-[21px] font-bold">معرفی شرکت</h2>
              <p>{foundCustomer.Introduction}</p>
            </div>
            {/* معرفی و شرح کالا و پروژه */}
            <div className={Style.explanation}>
              <h2 className="text-[21px] font-bold">شرح کلا پروژه</h2>
              <p>{foundCustomer.DescriptionOfProjects}</p>
            </div>
            {/*کانتینر  های دیاریس و آبی  */}
            <div className="flex flex-col    justify-between  items-start   mt-5   ">
              {/* کانتینر دیاریس */}
              {foundCustomer.DiyarisList?.length > 0 && (
                <div className="w-full ">
                  <div className="border-2 border-[#00c846] w-full p-2 bg-[#00c846]  rounded-t-2xl  flex  items-center flex-row justify-between h-16">
                    <img
                      src={Diyaris}
                      alt="Diyaris"
                      className="ml-2"
                      width={50}
                    />
                    <div>
                      <p className="text-white text-lg ">
                        اجزای عملیاتی پروژه در دیاریس
                      </p>
                    </div>
                  </div>
                  <div className="border-[3px] -t-1 relative w-full border-[#00c846] rounded-b-2xl">
                    <div className={Style.DiyaricPragraph}>
                      <ul>
                        {foundCustomer.DiyarisList?.map(
                          (diyarisItem, index) => (
                            <li key={index}>
                              <div className="flex flex-row">
                                {" "}
                                {/* <img src={Square} alt="Square" width={15} />{" "} */}
                                <h1 className="font-bold">
                                  {diyarisItem.head}
                                </h1>{" "}
                              </div>
                              <ul className="pt-1 pb-2">
                                {diyarisItem.items?.map((item, itemIndex) => (
                                  <li key={itemIndex} className="flex flex-row">
                                    <img
                                      src={Circle}
                                      alt="Circle"
                                      width={13}
                                      className="pr-1"
                                    />
                                    <p className="text-lg pr-1">{item}</p>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {/* کانتینر خانه آبی  */}
              {foundCustomer.AabiiiList?.length > 0 && (
                <div className="w-full  mt-4 ">
                  <div
                    className="  border-2  w-full border-blue-700 p-2 bg-blue-700 
              rounded-t-2xl  flex flex-row-reverse justify-between items-center h-16"
                  >
                    <div className="flex flex-row-reverse justify-between items-center  ">
                      <img src={Sign} alt="Sign" width={15} />
                      <p className="text-[16px] ml-2 text-white">
                        اجزای عملیاتی پروژه در استودیو طراحی آبی
                      </p>
                    </div>
                    <img
                      src={AABIII}
                      alt="AABIII"
                      width={50}
                      className="ml-2"
                    />
                  </div>
                  <div className="border-[3px] w-full  border-blue-700 rounded-b-2xl ">
                    <div className={Style.AbiiiPragraph}>
                      <ul>
                        {foundCustomer.AabiiiList?.map((aabiiiList, index) => (
                          <li key={index}>
                            <div className="flex flex-row">
                              {" "}
                              {/* <img src={Square} alt="Square" width={15} />{" "} */}
                              <h1 className="font-bold">{aabiiiList.head}</h1>{" "}
                            </div>
                            <ul>
                              {aabiiiList.items?.map((item, itemIndex) => (
                                <li key={itemIndex} className="flex flex-row">
                                  {" "}
                                  <img
                                    src={Squre}
                                    alt="Squre"
                                    width={13}
                                    className="pr-1"
                                  />{" "}
                                  <p className="text-lg pr-1">{item}</p>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <Picker/> */}
          </div>
        ) : (
          <div>مشتری پیدا نشد.</div>
        )}
         <div ><Footer/></div>   
      </div>
    </div>
  );
};

export default MobileDetails;
