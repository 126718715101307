import React, { useEffect, useRef, useState } from "react";
import Style from "./Style.module.scss";
import Form from "./components/form/Form";
import useScreenHeight from "hook/useScreenHeight";
import useWindowSize from "hook/useWindowSize";
import Footer from "Layout/Footer/Footer";
const MobileOrder = () => {
  const [state, setState] = useState(false);
  const screenHeight = useScreenHeight();
  //از این جا برای ثابت کردن اندازه استفاده می شود
  const { width, height } = useWindowSize();
  //این هم برای تنظیم اندازه ای که کانیتر اطلاعات ما می گیرد تنظیم می شود
  const padingHeightBottom = height / 6;
  const goForm = () => {
    setState(true);
  };
  return (
    <div>
      <div
        dir="rtl"
        className={`  mx-auto relative top-[10rem]  text-[21px] text-white`}
        style={{ height: `${height - 370}px` }}
      >
        {state === false ? (
          <div className="px-4">
            {/* <h3>درخواست سفارش اولیه</h3> */}
            <div>
              <p>
                شما با تکمیل فرم پیش رو می‌توانید در تسریع برداشت سازمان شما از
                سوی گروه چینش کمک کنید.
              </p>
              <div className={Style.Form}>
                <p>
                  لطفا در نظر داشته باشید اطلاعاتی که در فرم زیر وارد می‌کنید
                  باعث ایجاد یک پیش فرض و تصویر کلی از سازمان شما در گروه چینش
                  خواهد شد، که به این ذهنیت ایجاد شده کمک در انجام مذاکرات آتی
                  خواهد داشت. لذا میزان ۱۵ دقیقه زمان را با حوصله صرف تکمیل فرم
                  نمایید.
                </p>
              </div>
              <div className="flex  flex-col justify-around p-1 mt-2 items-end">
                <button
                  className="text-[#00c846]  focus:shadow-outline w-[10rem] focus:outline-none  border-2 border-[#00c846]  font-bold py-2 px-4 rounded-full whitespace-nowrap" // <-- here
                  onClick={goForm}
                >
                  فرم درخواست اولیه سفارش
                </button>
              </div>
            </div>
          </div>
        ) : (
          <Form />
        )}
        <div className="">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MobileOrder;
