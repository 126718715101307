import Loading from "components/Loding/Loding"; // Fixed typo in import statement
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import useMediaQuery from "hook/useMediaQuery";
import { useShowMenuMobile } from "zstand/ShowMenuMobile";
import MenuFooterPage from "components/MenuFooterPage/MenuFooterPage";
import { useChangeColor } from "zstand/BlackOrGreen";
import ImgHeder from "assets/JPG/diyaris-picture-s.jpg";
function Layout() {
  const location = useLocation();
  const homePage = location.pathname === "/";
  const servicePage = location.pathname === "/services";
  const diyaris = location.pathname === "/diyaris";
  const coustomerDetails = location.pathname.startsWith("/Customer");
  const FooterPage =
    location.pathname === "/OrderRequest" ||
    location.pathname === "//About%20Us" ||
    location.pathname === "/CareerOpportunities";
  const [isLoading, setIsLoading] = useState(true); // Set initial state to true
  const matches = useMediaQuery("(min-width: 832px)");
  const { shwoMenu, show, dontShow } = useShowMenuMobile();
  const { goGreen } = useChangeColor((state) => state.actions);
  useEffect(() => {
    if (!coustomerDetails) {
      // Show loading only if it's not a customer details page
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 2000);

      return () => clearTimeout(loadingTimeout);
    } else {
      // If it's a customer details page, set loading to false directly
      setIsLoading(false);
    }
  }, [coustomerDetails]); // The empty dependency array ensures this effect runs only once
  useEffect(() => {
    goGreen();
  }, [location.pathname]);
  return (
    <div>
      {isLoading ? (
        coustomerDetails ? null : (
          <Loading />
        )
      ) : (
        <>
          {matches ? (
            <div>
              {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}
              {!homePage && <Header />}

              <Outlet />

              {homePage || servicePage || diyaris || coustomerDetails ? null : (
                <Footer />
              )}
            </div>
          ) : (
            <div>
              {/* <img src={ImgHeder} alt="ImgHeder" className=" relative" /> */}
              {!homePage && <Header />}
              {(location.pathname === "/OrderRequest" ||
                location.pathname === "/About%20Us" ||
                location.pathname === "/CareerOpportunities") && (
                <MenuFooterPage />
              )}
               {/* <div className="fixed top-[0rem] overflow-y-auto bg-black">  */}
               <Outlet />
               {/* </div>  */}
              {/* {!homePage && <Footer />} */}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Layout;
