import React from "react";

const InputCode = ({ handleCode }) => {
  const okCode = () => {
    handleCode();
  };
  return (
    <div>
      <form>
        <div className="flex flex-col justify-around items-start">
          <label htmlFor="Code" className="ml-1">
            لطفا کد را وارد کنید
          </label>
          <input
            type="text"
            id="Code"
            className="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
          />
        
        </div>
        <div className="flex items-end justify-end mt-6">
            <button
              className="text-[#00c846]  focus:shadow-outline w-[10rem] focus:outline-none  border-2 border-[#00c846]  font-bold py-2 px-4 rounded-full"
              onClick={okCode}
            >
              ثبت
            </button>
          </div>
      </form>
    </div>
  );
};

export default InputCode;
