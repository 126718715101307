"use client";
import React from "react";
import ActLogos from "./components/ActLogos";
// import { Canvas } from "@react-three/fiber";
// import Sky from "./components/Sky";
import Style from "./Style.module.css";
import useMediaQuery from "hook/useMediaQuery";
import Footer from "Layout/Footer/Footer";
const Coustomers = () => {
  const matches = useMediaQuery("(min-width: 832px)");
  return (
    <div className="set">
     {/* {matches ? }  */}
     {matches ?  <h2 className="text-white text-center mt-10">انتخاب کنید</h2> : null }
      <div >
         <ActLogos />
      </div>
     {matches ? <Footer/> : <div className="z-50"> <br /><br /><br /> <Footer/></div>} 

      {/* {matches === false && <div className="fixed bottom-[0] left-0 right-0"><Footer/></div> }   */}
    </div>
  );
};

export default Coustomers;
