import React, { useEffect, useState, useRef } from "react";
import Style from "./Style.module.scss";
import Text from "assets/Text/services/db.json";
import PageOne from "./components/Page One/PageOne";
import PageTwo from "./components/Page Two/PageTwo";
import PageThree from "./components/Page Three/PageThree";
import PageFore from "./components/Page Fore/PageFore";
import PageFive from "./components/Page Five/PageFive";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import { useDispatch, useSelector } from "react-redux";
import Footer from "Layout/Footer/Footer";
import useWindowSize from "hook/useWindowSize";
import "./MyMenuApple.css";
import MenuApple from "components/Menu/Menu";

import "./ChineshMenu.css";
import useMediaQuery from "hook/useMediaQuery";
import MobileService from "./Mobile/MobileService";
const Services = () => {
  const menu = Text.map((item) => item.title);
  const [currentPage, setCurrentPage] = useState(0); // Added state for pagination
  const firstSlideRef = useRef();
  const secondSlideRef = useRef();
  const [active, setActive] = useState(null);
  const splideRef = useRef(null);
  const dispatch = useDispatch();
  const navItemsRef = useRef(null);
  const containerRef = useRef(null); // ایجاد ref برای کانتینر
  //از این جا برای ثابت کردن اندازه استفاده می شود
  const { width, height } = useWindowSize();
  //این هم برای تنظیم اندازه ای که کانیتر اطلاعات ما می گیرد تنظیم می شود
  const padingHeightBottom = height / 6;
  console.log("height",height);
  const Menu = [
    { id: 0, title: "طراحی ساختار کلان سازمان", pagination: "الف" },
    { id: 1, title: "تدوین و طراحی راهبرد‌ه‌ها", pagination: "ب" },
    { id: 2, title: "تدوین شناسه سازمانی و نام تجاری", pagination: "ج" },
    {
      id: 3,
      title: "برنامه ریزی و طراحی معماری نام تجاری (برند)",
      pagination: "د",
    },
    { id: 4, title: "برنامه‌ریزی و طراحی محصول", pagination: "ه" },
  ];
  const matches = useMediaQuery("(min-width: 833px)");
  useEffect(() => {
    if (active === null) {
      setActive(0);
    }
  }, [active]);
  useEffect(() => {
    if (firstSlideRef.current && secondSlideRef.current) {
      firstSlideRef.current.sync(secondSlideRef.current.splide);
    }
  }, [firstSlideRef, secondSlideRef]);

  const handleMenuItemClick = (splide) => {
    setActive(splide.index);
    console.log("active", active);
  };

  const handleSplide = (splide) => {
    setActive(splide.index);
  };
  // const handleMenuClick = (index) => {
  //   setActive(index);
  //   setCurrentPage(index);
  //   splideRef.current.go(index);
  //   firstSlideRef.current.splide.go(index);
  // };

  return (
    <div dir="rtl" className="w-full">
      {matches ? (
        <div>
          <div
            className={`w-[75vw] ${Style.Menu} h-16 mt-4 mx-auto  bg-white  rounded-full`}
          >
            <Splide
              ref={firstSlideRef}
              className="text-black relative -top-10"
              aria-label="Menu"
              options={{
                rewind: true,
                arrows: false,
                // gap: "1rem",
                type: "loop",
                direction: "rtl",
                perPage: 2,
                perMove: 1,
                focus: "center",
                pagination: false,
                wheel: true,
                omitEnd: true,
                isNavigation: true,
                speed: 1000,
                breakpoints: {
                  1213: {
                    perPage: 1,
                  },
                },
              }}
            >
              {Menu.map((item, index) => (
                <SplideSlide
                  key={index}
                  // onClick={() => handleMenuClick(index)}
                  className={`rounded-full text-center max-w-fit ${
                    index === active
                      ? "border-[3px]  text-[#00c846] mx-2   h-12  border-[#00c846] font-bold text-[21px] px-2 py-1 "
                      : "text-[18px] px-4 py-2   hover:text-black text-gray-400 rounded-full"
                  }`}
                >
                  {item.title}
                </SplideSlide>
              ))}
            </Splide>
          </div>
          <div>
            <div
              className={`${Style.Body}  mx-auto    overflow-y-auto rounded-2xl p-6 text-black`}
              style={{ height: `${height - 115}px` }}
            >
              <div className=" mx-auto">
                {active === 0 && <PageOne />}
                {active === 1 && <PageTwo />}
                {active === 2 && <PageThree />}
                {active === 3 && <PageFore />}
                {active === 4 && <PageFive />}
              </div>
            </div>
          </div>
          <Footer>
            <div className={`${Style.Pagination} rounded-full`}>
              <Splide
                ref={secondSlideRef}
                onMoved={handleSplide}
                hasTrack={false}
                options={{
                  rewind: true,
                  arrows: false,
                  type: "loop",
                  direction: "ttb",
                  rewindByDrag: true,
                  speed: 1000,
                  perPage: 3,
                  perMove: 1,
                  height: "8rem",
                  focus: "center",
                  pagination: false,
                  wheel: true,
                  omitEnd: true,
                  isNavigation: true,
                  breakpoints: {
                    600: {
                      perPage: 3,
                      height: "5rem",
                    },
                  },
                }}
              >
                <SplideTrack className="flex items-center justify-center">
                  {Menu.map((item, index) => (
                    <SplideSlide
                      key={index}
                      className={`${
                        index === active
                          ? ` border-[3px] w-14 font-bold text-[21px] text-[#00c846] mx-2 px-1 text-center py-1  border-[#00c846]  rounded-full`
                          : `text-[18px]   py-2  text-gray-400 rounded-full`
                      }`}
                    >
                      {item.pagination}
                    </SplideSlide>
                  ))}
                </SplideTrack>
              </Splide>
            </div>
          </Footer>
        </div>
      ) : (
        <MobileService Menu={Menu} />
      )}
    </div>
  );
};

export default Services;
